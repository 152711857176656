import React from 'react';
import {
  BooleanField,
  EditButton,
  EmailField,
  ListButton,
  NumberField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import PageTitle from '../PageTitle';
import UserViewTitle from './UserViewTitle';
import UserPosts from './UserPosts';
import UserTips from './UserTips';

const useStyles = makeStyles({
  toolbar: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  btn: { marginLeft: '0.5rem' },
  summary: {
    display: 'grid',
    gridTemplateRows: 'repeat(7, 1fr)',
    gridTemplateColumns: 'repeat(3, 1fr)'
  }
});

const UserShowActions = ({ basePath, data }) => {
  const classes = useStyles();

  return (
    <TopToolbar className={classes.toolbar}>
      <UserViewTitle view={'Show User'} record={data} />
      <div>
        <EditButton basePath={basePath} record={data} />
        <ListButton className={classes.btn} basePath={basePath} />
      </div>
    </TopToolbar>
  );
};

const UserShow = props => {
  const classes = useStyles();

  return (
    <Show
      title={<PageTitle {...props} />}
      actions={<UserShowActions />}
      {...props}
    >
      <TabbedShowLayout>
        <Tab label='summary' contentClassName={classes.summary}>
          <TextField source='id' />
          <TextField source='username' />
          <TextField source='displayName' />

          <EmailField source='email' />
          <TextField source='userRole' />
          <TextField source='paymentAccount' />

          <BooleanField source='verified' />
          <TextField source='dateJoined' />
          <NumberField source='viewCount' />

          <NumberField source='postCount' />
          <NumberField source='lessonCount' />
          <NumberField source='commentCount' />

          <NumberField source='tipsReceivedCount' />
          <NumberField source='tipsReceivedTotal' />
          <div />

          <NumberField source='tipsGivenCount' />
          <NumberField source='tipsGivenTotal' />
          <div />

          <NumberField source='bestDailyPostingStreak' />
          <NumberField source='bestWeeklyPostingStreak' />
          <NumberField source='sevenDayPostingStreaks' />
        </Tab>
        <Tab label='posts'>
          <UserPosts source='posts' />
        </Tab>

        <Tab label='recent tips' title='Last 200 Tips'>
          <UserTips source={'tips'} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default UserShow;
