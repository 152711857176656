import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  title: {
    display: 'flex',
    alignItems: 'center'
  },
  logo: {
    width: '32px',
    height: '32px'
  },
  name: { paddingLeft: '1rem' }
});

const PageTitle = () => {
  const classes = useStyles();

  return (
    <div className={classes.title}>
      <img
        className={classes.logo}
        src='https://res.cloudinary.com/dwlkh8tj3/image/upload/f_auto,q_auto,w_auto,c_scale/assets/img/Logo-white-small.png'
        alt=''
      />

      <span className={classes.name}>gfam admin</span>
    </div>
  );
};
export default PageTitle;
