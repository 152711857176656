import { useCallback, useEffect, useState } from 'react';
import { Labeled, useNotify } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import MonthlyBudgetDialog from './MonthlyBudgetDialog';

const useStyles = makeStyles({
  monthlyBudgetWrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    borderRadius: ' 4px',
    paddingLeft: '1rem',

    '& label': {
      color: '#3f51b5'
    }
  },
  monthlyBudget: {
    fontWeight: '400'
  },
  editBtn: {
    position: 'absolute',
    top: '0',
    right: '0.5rem'
  }
});

const MonthlyBudget = () => {
  const classes = useStyles();
  const notify = useNotify();

  const [budgetData, setBudgetData] = useState({
    monthlyBudget: 0,
    id: ''
  });
  const [isProcessing, setIsProcessing] = useState(false);

  const token = localStorage.getItem('auth');

  const getMonthlyBudget = useCallback(async () => {
    const url = `/api/activityboardconfig/monthlybudget`;

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token
      }
    })
      .then(async res => {
        const data = await res.json();
        setBudgetData(data);
      })
      .catch(e => {
        console.log('getMonthlyBudget/error: ', e);
        setBudgetData({
          monthlyBudget: 0,
          id: ''
        });
      });
  }, [setBudgetData, token]);

  useEffect(() => {
    getMonthlyBudget();
  }, [getMonthlyBudget]);

  const editMonthlyBudget = (budget, id) => {
    const data = {
      monthlyBudget: Number(budget)
    };

    setIsProcessing(true);

    fetch(`/api/activityboardconfig/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token
      },
      body: JSON.stringify(data)
    })
      .then(async res => {
        // const data = await res.json();
        // console.log('data: ', data);

        notify('Monthly reward budget updated', { type: 'success' });
      })
      .catch(e => {
        notify('Error: Monthly Budget Update Failed', { type: 'warning' });
      })
      .finally(() => {
        setIsProcessing(false);
        getMonthlyBudget();
      });
  };

  return (
    <div className={classes.monthlyBudgetWrapper}>
      <Labeled label='Monthly Prize Budget'>
        <span className={classes.monthlyBudget}>
          {budgetData.monthlyBudget} XRP
        </span>
      </Labeled>
      <span className={classes.editBtn}>
        <MonthlyBudgetDialog
          budget={budgetData.monthlyBudget}
          editMonthlyBudget={editMonthlyBudget}
          id={budgetData._id}
          isProcessing={isProcessing}
        />
      </span>
    </div>
  );
};

export default MonthlyBudget;
