import React from 'react';
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  NumberField,
  Pagination,
  ReferenceField,
  ShowButton,
  TextField
} from 'react-admin';

import PageTitle from '../PageTitle';
import SearchFilter from '../SearchFilter';
import CreateTipGroupButton from './CreateTipGroupButton';

import { makeStyles } from '@material-ui/core/styles';
import Done from '@material-ui/icons/Done';
import Clear from '@material-ui/icons/Clear';

const useStyles = makeStyles({
  listHeader: {
    fontSize: '1.25rem',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: '400',
    color: '#005362',
    margin: 'inherit',
    lineHeight: '1.6',
    letterSpacing: '0.0075em'
  },
  link: {
    color: '#028090'
  }
});

const LessonBulkActionButtons = props => (
  <>
    <CreateTipGroupButton {...props} />
  </>
);

const LessonList = props => {
  const classes = useStyles();

  return (
    <>
      <h6 className={classes.listHeader}>Lessons</h6>

      <List
        {...props}
        bulkActionButtons={<LessonBulkActionButtons />}
        filters={<SearchFilter />}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
        title={<PageTitle />}
      >
        <Datagrid rowClick='show'>
          <DateField label='Date' source='createdAt' showTime={true} />

          <ReferenceField
            label='User'
            source='user'
            reference='users'
            link='show'
            sortBy='username'
          >
            <TextField className={classes.link} source='username' />
          </ReferenceField>
          <FunctionField
            label='Title'
            render={record =>
              record.title.length > 42
                ? `${record.title.slice(0, 42)}...`
                : record.title
            }
          />
          <NumberField label='Tips' source='tipsTotal' />
          <FunctionField
            label='Gated'
            render={record => (record.isTipGated ? <Done /> : <Clear />)}
          />
          <ShowButton basePath='/lessons' label='Show' />
        </Datagrid>
      </List>
    </>
  );
};

export default LessonList;
