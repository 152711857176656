import TipGroupIcon from '@material-ui/icons/MonetizationOn';

import TipGroupEdit from './TipGroupEdit';
import TipGroupList from './TipGroupList';
import TipGroupShow from './TipGroupShow';

const resource = {
  list: TipGroupList,
  show: TipGroupShow,
  edit: TipGroupEdit,
  icon: TipGroupIcon
};

export default resource;
