import Leaderboard from '@material-ui/icons/Stars';

import LeaderboardList from './LeaderboardList';
import LeaderboardShow from './LeaderboardShow';

const resource = {
  list: LeaderboardList,
  show: LeaderboardShow,
  icon: Leaderboard
};

export default resource;
