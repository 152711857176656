import React from 'react';
import {
  Datagrid,
  DateField,
  FilterButton,
  List,
  NumberField,
  Pagination,
  ReferenceField,
  SelectInput,
  ShowButton,
  SimpleShowLayout,
  TextField,
  TopToolbar
} from 'react-admin';

import PageTitle from '../PageTitle';
import MarkRewardsPayableButton from './MarkRewardsPayableButton';
import SubmitPaymentsButton from './SubmitPaymentsButton';

import { showSubmitPytsBtn } from '../../util/filters';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  listHeader: {
    fontSize: '1.25rem',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: '400',
    color: '#005362',
    margin: 'inherit',
    lineHeight: '1.6',
    letterSpacing: '0.0075em'
  },
  link: {
    color: '#028090',
    textDecoration: 'none'
  },
  status: {
    textTransform: 'uppercase'
  }
});

const RewardListActions = props => (
  <TopToolbar>
    <FilterButton />
    <SubmitPaymentsButton
      showBtn={showSubmitPytsBtn(props.filterValues, props.ids)}
    />
  </TopToolbar>
);

const rewardFilters = [
  <SelectInput
    source='status'
    choices={[
      { id: 'pending', name: 'Pending' },
      { id: 'payable', name: 'Payable' },
      { id: 'submitted', name: 'Submitted' },
      { id: 'paid', name: 'Paid' },
      { id: 'failed', name: 'Failed' }
    ]}
  />
];

const RewardBulkActionButtons = props => (
  <>
    <MarkRewardsPayableButton {...props} />
  </>
);

const RewardPanel = () => (
  <SimpleShowLayout>
    <NumberField source='amount' />

    <TextField source='txHash' emptyText='No transaction' />
  </SimpleShowLayout>
);

const rewardIsSelectable = record => {
  switch (record.status) {
    case 'payable':
    case 'submitted':
    case 'paid':
      return false;

    case 'pending':
    case 'failed':
    default:
      return true;
  }
};

const RewardList = props => {
  const classes = useStyles();

  return (
    <>
      <h6 className={classes.listHeader}>Challenge Rewards</h6>

      <List
        {...props}
        filters={rewardFilters}
        actions={<RewardListActions />}
        title={<PageTitle />}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
        bulkActionButtons={<RewardBulkActionButtons />}
      >
        <Datagrid
          expand={<RewardPanel />}
          isRowSelectable={record => rewardIsSelectable(record)}
          rowClick='show'
        >
          <DateField
            label='Date'
            source='createdAt'
            options={{
              year: 'numeric',
              month: 'short',
              day: 'numeric'
            }}
          />
          <ReferenceField source='challenge' reference='challenges' link='show'>
            <TextField className={classes.link} source='title' />
          </ReferenceField>
          <ReferenceField source='user' reference='users' link='show'>
            <TextField className={classes.link} source='username' />
          </ReferenceField>

          <TextField source='status' className={classes.status} />
          <ShowButton basePath='/rewards' label='Show' />
        </Datagrid>
      </List>
    </>
  );
};

export default RewardList;
