import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const baseStyles = {
  display: 'inline-block',
  lineHeight: 1,
  verticalAlign: 'initial',
  textAlign: 'center',
  width: '1em',
  height: '1em',
  padding: '0.5em',
  backgroundColor: '#e8e8e8',
  color: '#767676',
  fontSize: '0.85714286rem',
  fontWeight: '700',
  border: '0 solid transparent',
  borderRadius: '50%'
};

const useStyles = makeStyles({
  base: { ...baseStyles },
  place1: {
    ...baseStyles,
    backgroundColor: '#a333c8',
    borderColor: '#a333c8',
    color: '#fff'
  },
  place2: {
    ...baseStyles,
    backgroundColor: '#2185d0',
    borderColor: '#2185d0',
    color: '#fff'
  },
  place3: {
    ...baseStyles,
    backgroundColor: '#00b5ad',
    borderColor: '#00b5ad',
    color: '#fff'
  },
  place4: {
    ...baseStyles,
    backgroundColor: '#21ba45',
    borderColor: '#21ba45',
    color: '#fff'
  },
  place5: {
    ...baseStyles,
    backgroundColor: '#b5cc18',
    borderColor: '#b5cc18',
    color: '#fff'
  }
});

const PlaceNumber = ({ place }) => {
  const classes = useStyles();

  const placeClass = number => {
    switch (number) {
      case 1:
        return classes.place1;
      case 2:
        return classes.place2;
      case 3:
        return classes.place3;
      case 4:
        return classes.place4;
      case 5:
        return classes.place5;
      default:
        return classes.base;
    }
  };

  return <span className={placeClass(place)}>{place}</span>;
};

export default PlaceNumber;
