import React from 'react';
import {
  DateField,
  Edit,
  FunctionField,
  ListButton,
  NumberField,
  NumberInput,
  RefreshButton,
  ShowButton,
  SimpleForm,
  TopToolbar
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import PageTitle from '../PageTitle';
import ActivityBoardViewTitle from './ActivityBoardViewTitle';

const useStyles = makeStyles({
  topToolbar: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  btn: { marginLeft: '0.5rem' },
  link: {
    color: '#028090',
    textDecoration: 'none'
  },
  processed: {
    textTransform: 'uppercase'
  }
});

const ActivityBoardActions = ({ basePath, data }) => {
  const classes = useStyles();
  return (
    <TopToolbar className={classes.topToolbar}>
      <ActivityBoardViewTitle view={'Edit Activity Board'} record={data} />
      <div>
        <ShowButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} className={classes.btn} />
        <RefreshButton className={classes.btn} />
      </div>
    </TopToolbar>
  );
};

const ActivityBoardEdit = props => {
  const classes = useStyles();

  return (
    <Edit
      title={<PageTitle {...props} />}
      actions={<ActivityBoardActions />}
      {...props}
    >
      <SimpleForm>
        <DateField
          label='Date'
          source='date'
          options={{
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          }}
        />

        <NumberInput label='Prize Fund' source='prizeFund' step={0.1} />

        <NumberField label='Total Score' source='totalScore' />

        <FunctionField
          label='User Count'
          render={record => <span>{record.scores.length}</span>}
        />

        <FunctionField
          label='Processed'
          render={record => (
            <span className={classes.processed}>
              {record.tipGroup ? 'yes' : 'no'}
            </span>
          )}
        />
      </SimpleForm>
    </Edit>
  );
};

export default ActivityBoardEdit;
