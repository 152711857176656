import React from 'react';
import {
  DateField,
  Labeled,
  NumberField,
  ReferenceField,
  TextField,
  useRecordContext
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  tips: {
    display: 'grid',
    gridTemplateRows: 'auto',
    rowGap: '0.5rem',
    paddingTop: '0.5rem',
    paddingBottom: '1rem'
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '2.25fr 1fr 2fr',
    columnGap: '1rem',
    width: 'max-content',
    minWidth: '22rem',
    alignItems: 'baseline',
    fontSize: '0.875rem',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: '400',
    lineHeight: '1.43',
    letterSpacing: '0.01071em'
  },
  tip: {
    display: 'grid',
    gridTemplateColumns: '2.25fr 1fr 2fr',
    columnGap: '1rem',
    width: 'max-content',
    minWidth: '22rem'
  },
  link: {
    color: '#028090'
  },
  msg: {
    textTransform: 'uppercase'
  },
  xrp: {
    fontSize: '12px',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: '400',
    lineHeight: '1.43',
    letterSpacing: '0.01071em'
  }
});

const PostTipList = props => {
  const { upvotes } = useRecordContext(props);
  const classes = useStyles();

  return (
    <div className={classes.tips}>
      <div className={classes.header}>
        <span>Date</span>
        <span>Amount</span>
        <span>Donor</span>
      </div>
      {upvotes.length > 0 ? (
        upvotes.map(tip => (
          <div key={tip._id} className={classes.tip}>
            <DateField
              record={tip}
              source='tip.createdAt'
              options={{
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric'
              }}
            />

            <div>
              <NumberField record={tip} source='amount' />
              <span className={classes.xrp}> XRP</span>
            </div>

            <ReferenceField
              record={tip}
              source='user._id'
              reference='users'
              link='show'
            >
              <TextField className={classes.link} source='username' />
            </ReferenceField>
          </div>
        ))
      ) : (
        <Labeled className={classes.msg} label='no tips found'></Labeled>
      )}
    </div>
  );
};

export default PostTipList;
