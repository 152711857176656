import * as React from 'react';
import {
  Create,
  DateInput,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

import PageTitle from '../PageTitle';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  formHeader: {
    fontSize: '1.25rem',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: '400',
    color: '#005362',
    margin: 'inherit',
    lineHeight: '1.6',
    letterSpacing: '0.0075em'
  }
});

const ChallengeCreate = props => {
  const classes = useStyles();

  return (
    <>
      <h6 className={classes.formHeader}>Create Challenge</h6>

      <Create {...props} title={<PageTitle />}>
        <SimpleForm redirect='list'>
          <TextInput source='companyName' />
          <TextInput source='title' fullWidth />
          <TextInput source='shortDescription' fullWidth />
          <RichTextInput source='description' />
          <SelectInput
            source='category'
            choices={[
              { id: 'social boost', name: 'Social Boost' },
              { id: 'content creation', name: 'Content Creation' },
              { id: 'creative challenge', name: 'Creative Challenge' },
              { id: 'quest', name: 'Quest' },
              { id: 'other', name: 'Other' }
            ]}
          />
          <NumberInput source='rewardsTotal' />
          <NumberInput source='defaultReward' />
          <DateInput source='expirationDate' />
        </SimpleForm>
      </Create>
    </>
  );
};
export default ChallengeCreate;
