import React from 'react';
import {
  BooleanInput,
  DateTimeInput,
  Edit,
  ImageField,
  FormDataConsumer,
  ListButton,
  // NumberInput,
  RefreshButton,
  required,
  ShowButton,
  SimpleForm,
  TextInput,
  TopToolbar
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import PageTitle from '../PageTitle';
import AdViewTitle from './AdViewTitle';

const useStyles = makeStyles({
  topToolbar: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  btn: { marginLeft: '0.5rem' },
  leftInput: {
    display: 'inline-block',
    width: '48%',
    marginRight: '1.5rem'
  },
  rightInput: {
    display: 'inline-block',
    width: '48%'
  },
  inlineInput: {
    width: '100%'
  },
  singleInput: {
    width: '48%'
  },
  urlInput: {
    width: '100%'
  }
});

const AdEditActions = ({ basePath, data }) => {
  const classes = useStyles();
  return (
    <TopToolbar className={classes.topToolbar}>
      <AdViewTitle view={'Edit Ad'} record={data} />
      <div>
        <ShowButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} className={classes.btn} />
        <RefreshButton className={classes.btn} />
      </div>
    </TopToolbar>
  );
};

const validateRequired = required();

const AdEdit = props => {
  const classes = useStyles();

  return (
    <Edit
      title={<PageTitle {...props} />}
      actions={<AdEditActions />}
      {...props}
    >
      <SimpleForm>
        <BooleanInput id='active-toggle' source='active' />
        <TextInput
          formClassName={classes.leftInput}
          className={classes.inlineInput}
          source='client'
          validate={validateRequired}
        />
        <TextInput
          formClassName={classes.rightInput}
          className={classes.inlineInput}
          source='campaign'
        />

        {/* <NumberInput
          className={classes.singleInput}
          source='spendTotal'
          validate={validateRequired}
        /> */}

        <DateTimeInput
          formClassName={classes.leftInput}
          className={classes.inlineInput}
          source='startDate'
          validate={validateRequired}
        />
        <DateTimeInput
          formClassName={classes.rightInput}
          className={classes.inlineInput}
          source='endDate'
          validate={validateRequired}
        />

        <TextInput
          className={classes.urlInput}
          label='Ad Link URL'
          source='linkURL'
          validate={validateRequired}
        />

        <ImageField label='Ad Image' source='imageURL' title='ad image' />

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.cloudinaryPublicId && (
              <TextInput
                className={classes.urlInput}
                label='Ad image URL'
                source='imageURL'
              />
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default AdEdit;
