import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import MoneyOff from '@material-ui/icons/MoneyOff';

import useCheckIsRestrictedUser from './useCheckIsRestrictedUser';

const useStyles = makeStyles({
  restrictButton: {
    color: '#f44336',
    marginLeft: 'auto',
    marginRight: '1rem',

    '&:hover': {
      backgroundColor: 'rgba(244, 67, 54, 0.12)'
    }
  },
  restrictedButton: {
    marginLeft: 'auto',
    marginRight: '1rem'
  }
});

const RestrictedUserButton = ({ record }) => {
  console.log('RestrictedUserButton record: ', record);
  const classes = useStyles();

  const isRestrictedUser = useCheckIsRestrictedUser(record.id);
  console.log('isRestrictedUser: ', isRestrictedUser);

  return isRestrictedUser ? (
    <Button
      component={Link}
      title='User Restricted'
      label='User Restricted'
      to={{
        pathname: '/restrictedusers',
        state: { record: { user: record.id } }
      }}
      className={classes.restrictedButton}
    >
      <MoneyOff />
    </Button>
  ) : (
    <Button
      component={Link}
      title='Go to Restrict Users List'
      label='Restrict User'
      to={{
        pathname: '/restrictedusers/create',
        state: { record: { user: record.id } }
      }}
      className={classes.restrictButton}
    >
      <MoneyOff />
    </Button>
  );
};

export default RestrictedUserButton;
