import React from 'react';
import {
  BulkDeleteButton,
  Datagrid,
  DateField,
  Labeled,
  List,
  Pagination,
  ReferenceField,
  RichTextField,
  ShowButton,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useListContext,
  useRecordContext
} from 'react-admin';

import PageTitle from '../PageTitle';

import AcceptResponseButton from './AcceptResponseButton';
import RejectResponseButton from './RejectResponseButton';
import ChallengeFilterButton from './ChallengeFilterButton';
import ChallengeFilterForm from './ChallengeFilterForm';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  listHeader: {
    fontSize: '1.25rem',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: '400',
    color: '#005362',
    margin: 'inherit',
    lineHeight: '1.6',
    letterSpacing: '0.0075em'
  },
  image: {
    maxWidth: '40em',
    maxHeight: '15em'
  },
  link: {
    color: '#028090',
    textDecoration: 'none'
  },
  status: {
    textTransform: 'uppercase'
  }
});

const ListActions = () => (
  <TopToolbar style={{ width: '100%' }}>
    <ChallengeFilterForm />
    <ChallengeFilterButton />
  </TopToolbar>
);

const ResponseBulkActionButtons = props => (
  <>
    <AcceptResponseButton {...props} />
    <RejectResponseButton {...props} />
    <BulkDeleteButton {...props} />
  </>
);

const ResponsePanel = () => {
  const record = useRecordContext();
  const classes = useStyles();
  return (
    <SimpleShowLayout>
      <Labeled label='Image'>
        <img
          src={`https://res.cloudinary.com/dwlkh8tj3/image/upload/${record.photoPublicId}`}
          className={classes.image}
          alt=''
        />
      </Labeled>

      <RichTextField source='content' />
    </SimpleShowLayout>
  );
};

const ResponseList = props => {
  const classes = useStyles();
  const list = useListContext();
  console.log(list);

  return (
    <>
      <h6 className={classes.listHeader}>Challenge Responses</h6>

      <List
        {...props}
        actions={<ListActions />}
        title={<PageTitle />}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
        bulkActionButtons={<ResponseBulkActionButtons />}
      >
        <Datagrid expand={<ResponsePanel />} rowClick='show'>
          <DateField
            label='Date'
            source='createdAt'
            options={{
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric'
            }}
          />
          <ReferenceField source='challenge' reference='challenges' link='show'>
            <TextField className={classes.link} source='title' />
          </ReferenceField>
          <ReferenceField source='user' reference='users' link='show'>
            <TextField className={classes.link} source='username' />
          </ReferenceField>

          <TextField source='status' className={classes.status} />
          <ShowButton basePath='/responses' label='Show' />
        </Datagrid>
      </List>
    </>
  );
};

export default ResponseList;
