import SettingsIcon from '@material-ui/icons/Settings';

import SettingsEdit from './SettingsEdit';
import SettingsList from './SettingsList';

const resource = {
  list: SettingsList,
  edit: SettingsEdit,
  icon: SettingsIcon
};

export default resource;
