import React from 'react';
import {
  DateField,
  Edit,
  ListButton,
  ReferenceField,
  RefreshButton,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import PageTitle from '../PageTitle';
import ChatViewTitle from './ChatViewTitle';

const useStyles = makeStyles({
  topToolbar: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  btn: { marginLeft: '0.5rem' },
  link: {
    color: '#028090',
    textDecoration: 'none'
  }
});

const ChatEditActions = ({ basePath, data }) => {
  const classes = useStyles();
  return (
    <TopToolbar className={classes.topToolbar}>
      <ChatViewTitle view={'Edit Chat Message'} record={data} />
      <div>
        <ListButton basePath={basePath} className={classes.btn} />
        <RefreshButton className={classes.btn} />
      </div>
    </TopToolbar>
  );
};

const ChatEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);

const ChatEdit = props => {
  const classes = useStyles();

  return (
    <Edit
      title={<PageTitle {...props} />}
      actions={<ChatEditActions />}
      {...props}
    >
      <SimpleForm toolbar={<ChatEditToolbar />}>
        <DateField label='Date' source='createdAt' showTime />

        <ReferenceField
          label='User'
          source='from'
          reference='users'
          link='show'
        >
          <TextField className={classes.link} source='username' />
        </ReferenceField>

        <TextInput source='body' />
      </SimpleForm>
    </Edit>
  );
};

export default ChatEdit;
