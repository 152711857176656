import React from 'react';
import {
  BooleanField,
  CreateButton,
  Datagrid,
  DateField,
  // FunctionField,
  List,
  Pagination,
  ReferenceField,
  TextField,
  TopToolbar
} from 'react-admin';

import PageTitle from '../PageTitle';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  listHeader: {
    fontSize: '1.25rem',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: '400',
    color: '#005362',
    margin: 'inherit',
    lineHeight: '1.6',
    letterSpacing: '0.0075em'
  },

  link: {
    color: '#028090',
    textDecoration: 'none'
  },
  boolean: {
    '& span': {
      '& svg[data-testid="false"]': {
        color: 'transparent'
      },
      '& svg[data-testid="true"]': {
        color: '#3f51b5;'
      }
    }
  }
});

const ListActions = () => (
  <TopToolbar>
    <CreateButton label='Add Restricted User' />
  </TopToolbar>
);

const RestrictedUserList = props => {
  const classes = useStyles();

  return (
    <>
      <h6 className={classes.listHeader}>Restricted Users List</h6>

      <List
        {...props}
        actions={<ListActions />}
        title={<PageTitle />}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
        perPage={25}
      >
        <Datagrid isRowSelectable={() => false} rowClick='show'>
          <DateField label='Date Added' source='createdAt' />

          <ReferenceField
            label='Username'
            source='user'
            reference='users'
            link='show'
            sortBy='username'
          >
            <TextField className={classes.link} source='username' />
          </ReferenceField>

          <TextField label='User ID' source='user' />

          <BooleanField
            label='Permanent'
            source='permanent'
            className={classes.boolean}
          />

          <TextField source='notes' />
        </Datagrid>
      </List>
    </>
  );
};

export default RestrictedUserList;
