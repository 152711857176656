import { useEffect, useState } from 'react';
import { Labeled } from 'react-admin';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles({
  title: {
    color: '#005362'
  },
  field: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',

    '& label': {
      color: '#3f51b5'
    }
  },
  wrapper: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    borderRadius: ' 4px',
    padding: '0 0 0 1rem'
  },
  input: {
    maxWidth: '120px'
  }
});

const muiInput = 'MuiInputBase-input MuiInputBase-inputMarginDense';
const muiInputWrapper =
  'MuiInputBase-root MuiFilledInput-root MuiFilledInput-underline MuiInputBase-formControl MuiInputBase-marginDense MuiFilledInput-marginDense';

const MonthlyBudgetDialog = ({
  budget,
  editMonthlyBudget,
  id,
  isProcessing = false
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    setAmount(budget);
  }, [budget]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = () => {
    editMonthlyBudget(amount, id);
    setOpen(false);
  };

  return (
    <div>
      <IconButton size='small' color='primary' onClick={handleClickOpen}>
        <EditIcon fontSize='small' />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='edit-dialog-title'
        aria-describedby='edit-dialog-description'
      >
        <DialogTitle id='edit-dialog-title' className={classes.title}>
          {'Edit Monthly Prize Budget'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='edit-dialog-description'>
            {'Enter the current monthly prize budget for Activity Scoreboards'}
          </DialogContentText>
          <div className={classes.field}>
            <div className={classes.wrapper}>
              <Labeled label='Monthly Prize Budget'>
                <div className={muiInputWrapper}>
                  <input
                    id='amount'
                    name='amount'
                    className={`${muiInput} ${classes.input}`}
                    type='number'
                    value={amount}
                    step='0.1'
                    min='0.1'
                    onChange={e => setAmount(e.target.value)}
                  />
                </div>
              </Labeled>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleEdit}
            color='primary'
            variant='contained'
            autoFocus
            disableElevation
            disabled={budget === 0 || isProcessing}
            loading={isProcessing.toString()}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MonthlyBudgetDialog;
