import React, { useState } from 'react';
import {
  Datagrid,
  NumberField,
  TextField,
  useQuery,
  Loading,
  Error
} from 'react-admin';

import { CardContent, Container, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    marginBottom: '1.5rem'
  },
  header: {
    color: '#005362'
  },
  userCount: {
    color: '#005362',
    paddingRight: '1rem'
  },
  table: {
    padding: '0.5rem'
  }
});

const Stats = () => {
  const [sort, setSort] = useState({ field: 'displayOrder', order: 'ASC' });
  const classes = useStyles();

  const { data, loading, error } = useQuery({
    type: 'getOne',
    resource: 'stats',
    payload: { id: 'stats' }
  });

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!data) return null;

  const { userCount, dateRangeIds, dateRanges } = data;

  return (
    <Container className={classes.container}>
      <CardContent>
        <Typography className={classes.header} variant='h6' gutterBottom>
          App Stats
        </Typography>
        <Typography variant='subtitle1' gutterBottom>
          <span className={classes.userCount}>As of:</span>
          <span>{new Date().toDateString()}</span>
        </Typography>
        <Typography variant='subtitle1' gutterBottom>
          <span className={classes.userCount}>User Count:</span>
          <span>{userCount}</span>
        </Typography>
      </CardContent>
      <Divider />
      <Datagrid
        data={dateRanges}
        ids={dateRangeIds}
        currentSort={sort}
        setSort={(field, order) =>
          setSort({ field: 'displayOrder', order: 'ASC' })
        }
        className={classes.table}
      >
        <TextField source='dateRange' />
        <NumberField label='Views' source='viewCount' />
        <NumberField label='Posts' source='postCount' />
        <NumberField label='Lessons' source='lessonCount' />
        <NumberField label='Challenges' source='challengeCount' />
        <NumberField label='Responses' source='responseCount' />
        <NumberField label='Tips' source='tipCount' />
        <NumberField label='Tip/Reward total' source='tipTotal' />
      </Datagrid>
    </Container>
  );
};

export default Stats;
