import React from 'react';
import {
  EditButton,
  DateField,
  DeleteButton,
  ListButton,
  NumberField,
  RichTextField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import PageTitle from '../PageTitle';
import ChallengeViewTitle from './ChallengeViewTitle';
import ResponseList from './ResponseList';
import RewardList from './RewardList';
import CommentList from './CommentList';

const useStyles = makeStyles({
  toolbar: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  btn: { marginLeft: '0.5rem' },
  status: {
    '& span': {
      textTransform: 'uppercase'
    },
    '& label': {
      '& span': {
        textTransform: 'capitalize'
      }
    }
  },
  category: {
    textTransform: 'capitalize'
  }
});

const ChallengeShowActions = ({ basePath, data }) => {
  const classes = useStyles();
  return (
    <TopToolbar className={classes.toolbar}>
      <ChallengeViewTitle view={'Show Challenge'} record={data} />
      <div>
        <EditButton basePath={basePath} record={data} />
        <DeleteButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} className={classes.btn} />
      </div>
    </TopToolbar>
  );
};

const ChallengeShow = props => {
  const classes = useStyles();

  return (
    <Show
      title={<PageTitle {...props} />}
      actions={<ChallengeShowActions />}
      {...props}
    >
      <TabbedShowLayout>
        <Tab label='summary'>
          <DateField label='Date' source='createdAt' />
          <DateField label='Expiry' source='expirationDate' />
          <TextField
            className={classes.status}
            label='Status'
            source='status'
          />
          <TextField
            className={classes.category}
            label='Category'
            source='category'
          />
          <TextField label='Company' source='companyName' />
          <TextField label='Title' source='title' />
          <RichTextField label='shortDescription' source='shortDescription' />
          <RichTextField label='Description' source='description' />
          <NumberField label='Rewards' source='rewardsTotal' />
          <NumberField label='Remaining' source='rewardsRemaining' />
          <NumberField label='Default' source='defaultReward' />
        </Tab>
        <Tab label='responses'>
          <ResponseList source='responses' />
        </Tab>

        <Tab label='rewards'>
          <RewardList source='rewards' />
        </Tab>
        <Tab label='comments'>
          <CommentList source='comments' />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default ChallengeShow;
