import React from 'react';
import {
  CreateButton,
  Datagrid,
  DateField,
  Filter,
  List,
  NumberField,
  Pagination,
  SearchInput,
  SimpleShowLayout,
  ShowButton,
  TextField,
  TopToolbar
} from 'react-admin';

import PageTitle from '../PageTitle';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  listHeader: {
    fontSize: '1.25rem',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: '400',
    color: '#005362',
    margin: 'inherit',
    lineHeight: '1.6',
    letterSpacing: '0.0075em'
  },
  category: {
    textTransform: 'capitalize'
  },
  status: {
    textTransform: 'uppercase'
  },
  panel: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr'
  }
});

const ListActions = () => (
  <TopToolbar>
    <CreateButton />
  </TopToolbar>
);

const ChallengeFilters = props => (
  <Filter {...props}>
    <SearchInput source='q' alwaysOn />
  </Filter>
);

const ChallengePanel = () => {
  const classes = useStyles();
  return (
    <SimpleShowLayout className={classes.panel}>
      <NumberField label='Rewards Remaining' source='rewardsRemaining' />
      <NumberField label='Total Rewards' source='rewardsTotal' />
      <NumberField label='Default Reward' source='defaultReward' />
      <NumberField label='Rewards Issued' source='rewardCount' />
      <NumberField label='Response Count' source='responseCount' />
      <NumberField label='Comment Count' source='commentCount' />
    </SimpleShowLayout>
  );
};

const ChallengeList = props => {
  const classes = useStyles();

  return (
    <>
      <h6 className={classes.listHeader}>Challenges</h6>

      <List
        {...props}
        actions={<ListActions />}
        title={<PageTitle />}
        filters={<ChallengeFilters />}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
      >
        <Datagrid
          expand={<ChallengePanel />}
          isRowSelectable={() => false}
          rowClick='show'
        >
          <DateField label='Date' source='createdAt' />
          <TextField
            className={classes.category}
            label='Category'
            source='category'
          />
          <TextField label='Company' source='companyName' />
          <TextField label='Title' source='title' />
          <TextField source='status' className={classes.status} />
          <ShowButton basePath='/challenges' label='Show' />
        </Datagrid>
      </List>
    </>
  );
};

export default ChallengeList;
