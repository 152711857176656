import * as React from 'react';
import { useState } from 'react';
import { Button, Confirm, useNotify, useRefresh } from 'react-admin';

import CloudUpload from '@material-ui/icons/CloudUpload';

const SubmitTipGroupButton = ({ record }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem('auth');
  const headers = {
    'Content-Type': 'application/json',
    'x-auth-token': token
  };

  const submitTipGroup = () => {
    const groupData = {
      groupId: record.id
    };
    console.log('groupData: ', groupData);

    setLoading(true);

    fetch(`/api/tipgroups/submit`, {
      method: 'POST',
      headers,
      body: JSON.stringify(groupData)
    })
      .then(async res => {
        const data = await res.json();
        console.log('data: ', data);
        notify(data.message, { type: data.success ? 'success' : 'warning' });
      })
      .catch(e => {
        notify('Error: tip group not submitted', { type: 'warning' });
      })
      .finally(() => {
        setLoading(false);
        refresh();
      });
  };

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    submitTipGroup();
    setOpen(false);
  };

  const showBtn = !record.submitted;

  return (
    <>
      {showBtn && (
        <>
          <Button
            label='Submit Tip Group'
            onClick={handleClick}
            disabled={loading}
          >
            <CloudUpload />
          </Button>

          <Confirm
            isOpen={open}
            title='Submit Tip Group'
            content='Are you sure you want to submit this tip group for payment?'
            onConfirm={handleConfirm}
            onClose={handleDialogClose}
          />
        </>
      )}
    </>
  );
};

export default SubmitTipGroupButton;
