import React from 'react';
import {
  BooleanField,
  EditButton,
  DateField,
  FunctionField,
  ImageField,
  ListButton,
  NumberField,
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import PageTitle from '../PageTitle';
import PostViewTitle from './PostViewTitle';
import PostCommentList from './PostCommentList';
import PostTipList from './PostTipList';

const useStyles = makeStyles({
  toolbar: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  btn: { marginLeft: '0.5rem' },
  link: { color: '#028090' }
});

const PostShowActions = ({ basePath, data }) => {
  const classes = useStyles();
  return (
    <TopToolbar className={classes.toolbar}>
      <PostViewTitle view={'Show Post'} record={data} />
      <div>
        <EditButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} className={classes.btn} />
      </div>
    </TopToolbar>
  );
};

const PostShow = props => {
  const classes = useStyles();

  return (
    <Show
      title={<PageTitle {...props} />}
      actions={<PostShowActions />}
      {...props}
    >
      <TabbedShowLayout>
        <Tab label='summary'>
          <DateField label='Date' source='date' showTime={true} />
          <TextField source='author' />
          <TextField source='title' />
          <NumberField source='views' />
          <NumberField source='commentCount' />
          <NumberField source='upvoteCount' />
          <BooleanField source='isGated' label='Tip Gated' />
          <ReferenceField label='Ad' source='ad' reference='ads' link='show'>
            <FunctionField
              label='Banner Ad'
              render={record => (
                <div className={classes.link}>
                  <span>{record.client}</span>
                </div>
              )}
            />
          </ReferenceField>
          <BooleanField source='hiddenFromFeed' />
        </Tab>

        <Tab label='content'>
          <ImageField source='image' title='post image' />
          <TextField source='title' />
          <TextField source='description' />
          <FunctionField
            label='Story'
            render={record => (
              <div dangerouslySetInnerHTML={{ __html: record.story }} />
            )}
          />
        </Tab>
        <Tab label='comments'>
          <PostCommentList source='comments' />
        </Tab>

        <Tab label='tips'>
          <PostTipList source='upvotes' />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default PostShow;
