import React from 'react';
import {
  ArrayInput,
  DateField,
  Edit,
  FormDataConsumer,
  Labeled,
  ListButton,
  NumberInput,
  RefreshButton,
  ReferenceField,
  SaveButton,
  ShowButton,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  Toolbar,
  TopToolbar
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import PageTitle from '../PageTitle';
import TipGroupViewTitle from './TipGroupViewTitle';

const useStyles = makeStyles({
  topToolbar: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  btn: { marginLeft: '0.5rem' },
  link: {
    color: '#028090',
    textDecoration: 'none',
    fontSize: '1rem'
  },
  status: {
    textTransform: 'uppercase'
  },
  tip: {
    '& section': {
      display: 'flex',
      alignItems: 'start',
      columnGap: '1.5rem'
    }
  },
  user: {
    width: '10rem',
    paddingLeft: '0.5rem'
  }
});

const TipGroupEditActions = ({ basePath, data }) => {
  const classes = useStyles();

  return (
    <TopToolbar className={classes.topToolbar}>
      <TipGroupViewTitle view={'Edit Tip Group'} record={data} />
      <div>
        <ShowButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} className={classes.btn} />
        <RefreshButton className={classes.btn} />
      </div>
    </TopToolbar>
  );
};

const TipGroupEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);

const TipGroupEdit = props => {
  const classes = useStyles();

  return (
    <Edit
      title={<PageTitle {...props} />}
      actions={<TipGroupEditActions />}
      {...props}
    >
      <SimpleForm toolbar={<TipGroupEditToolbar />}>
        <DateField
          label='Date'
          source='createdAt'
          options={{
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          }}
        />

        <TextField source='finished' className={classes.status} />

        <TextField source='targetType' />
        <ArrayInput source='tips'>
          <SimpleFormIterator
            disableAdd
            disableRemove
            disableReordering
            className={classes.tip}
          >
            <FormDataConsumer>
              {({ getSource, scopedFormData }) => {
                return (
                  <ReferenceField
                    label='User Id'
                    source={getSource('user')}
                    reference='users'
                    link='show'
                  >
                    <Labeled label='User' className={classes.user}>
                      <TextField
                        className={classes.link}
                        record={scopedFormData}
                        source='username'
                      />
                    </Labeled>
                  </ReferenceField>
                );
              }}
            </FormDataConsumer>
            <FormDataConsumer>
              {({ getSource, scopedFormData }) => {
                /* eslint-disable no-unused-vars */
                const source = getSource('targetId');
                return (
                  <Labeled label='Target Id' className={classes.targetId}>
                    <TextField
                      label='Target Id'
                      source={'targetId'}
                      record={scopedFormData}
                    />
                  </Labeled>
                );
              }}
            </FormDataConsumer>
            <NumberInput label='Tip amount' source='amount' />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default TipGroupEdit;
