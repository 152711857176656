import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const transformations = {
  ASSET_IMG: `f_auto,q_auto`,
  ASSET_IMG_LARGE: `f_auto,q_auto,w_auto,c_scale`
};

const types = {
  IMAGE: transformations.ASSET_IMG,
  IMAGE_LARGE: transformations.ASSET_IMG_LARGE
};

const config = {
  cloud_name: `dwlkh8tj3`,
  upload_url: `https://api.cloudinary.com/v1_1/dwlkh8tj3/image/upload`,
  upload_preset: `wd1o91y9`,
  base_url: `https://res.cloudinary.com/dwlkh8tj3/image/upload`
};

const uploadImage = async (imageFile, filePrefix = 'adImages') => {
  try {
    if (!imageFile) {
      return null;
    }

    const publicId = `${filePrefix}/${uuidv4()}`;

    const requestData = {
      file: imageFile,
      upload_preset: config.upload_preset,
      public_id: publicId
    };

    const response = await axios.post(config.upload_url, requestData);
    const { secure_url, public_id } = response.data;

    return {
      imageURL: secure_url,
      cloudinaryPublicId: public_id
    };
  } catch (error) {
    console.log('upload image error: ', error);
    return null;
  }
};

const cloudinary = {
  config,
  transformations,
  types,
  t_asset_img: `f_auto,q_auto`,
  t_asset_img_lrg: `f_auto,q_auto,w_auto,c_scale`,
  uploadImage
};

export default cloudinary;
