import { makeStyles } from '@material-ui/core/styles';

export const useLeaderboardListStyles = makeStyles({
  list: {
    display: 'grid',
    gridTemplateRows: 'auto',
    rowGap: '0.5rem',
    paddingTop: '0.5rem',
    paddingBottom: '1rem'
  },
  header: {
    color: 'rgba(0, 0, 0, 0.70)',
    padding: 0,
    fontSize: '1rem',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: '400',
    lineHeight: '1.43',
    letterSpacing: '0.01071em'
  },
  headerWithMargin: {
    marginTop: '0.8rem',
    padding: 0,
    color: 'rgba(0, 0, 0, 0.70)',
    fontSize: '1rem',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: '400',
    lineHeight: '1.43',
    letterSpacing: '0.01071em'
  },
  msg: {
    textTransform: 'uppercase'
  }
});
