import CommentIcon from '@material-ui/icons/Comment';

import ResponseList from './ResponseList';
import ResponseShow from './ResponseShow';
import ResponseEdit from './ResponseEdit';

const resource = {
  list: ResponseList,
  show: ResponseShow,
  edit: ResponseEdit,
  icon: CommentIcon
};

export default resource;
