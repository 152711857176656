import MoneyOff from '@material-ui/icons/MoneyOff';

import RestrictedUserCreate from './RestrictedUserCreate';
import RestrictedUserEdit from './RestrictedUserEdit';
import RestrictedUserList from './RestrictedUserList';
import RestrictedUserShow from './RestrictedUserShow';

const resource = {
  list: RestrictedUserList,
  show: RestrictedUserShow,
  create: RestrictedUserCreate,
  edit: RestrictedUserEdit,
  icon: MoneyOff
};

export default resource;
