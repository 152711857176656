import React from 'react';
import {
  DateField,
  DeleteButton,
  EditButton,
  FunctionField,
  ListButton,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import PageTitle from '../PageTitle';
import RestrictedUserViewTitle from './RestrictedUserViewTitle';

const useStyles = makeStyles({
  toolbar: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  btn: { marginLeft: '0.5rem' },
  link: {
    color: '#028090',
    textDecoration: 'none'
  },
  permanent: {
    textTransform: 'uppercase'
  }
});

const RestrictedUserShowActions = ({ basePath, data }) => {
  const classes = useStyles();
  return (
    <TopToolbar className={classes.toolbar}>
      <RestrictedUserViewTitle view={'Show Restricted User'} record={data} />
      <div>
        <EditButton basePath={basePath} record={data} />
        <DeleteButton
          label='Remove Restriction'
          basePath={basePath}
          record={data}
        />
        <ListButton basePath={basePath} className={classes.btn} />
      </div>
    </TopToolbar>
  );
};

const RestrictedUserShow = props => {
  const classes = useStyles();

  return (
    <Show
      title={<PageTitle {...props} />}
      actions={<RestrictedUserShowActions />}
      {...props}
    >
      <SimpleShowLayout>
        <DateField label='Date Added' source='createdAt' showTime />

        <ReferenceField
          label='Username'
          source='user'
          reference='users'
          link='show'
          sortBy='username'
        >
          <TextField className={classes.link} source='username' />
        </ReferenceField>

        <TextField label='User ID' source='user' />

        <FunctionField
          label='Permanent'
          render={record => (
            <span className={classes.permanent}>
              {record.permanent ? 'yes' : 'no'}
            </span>
          )}
        />

        <TextField source='notes' />
      </SimpleShowLayout>
    </Show>
  );
};

export default RestrictedUserShow;
