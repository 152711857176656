import React from 'react';

import ListItem from './ListItem';

const List = ({ leaders }) => {
  const listItems = leaders.map((leader, idx) => {
    return (
      <span key={leader._id}>
        <ListItem leader={leader} place={idx + 1} />
      </span>
    );
  });
  return listItems;
};

export default List;
