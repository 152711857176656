import React from 'react';
import {
  BulkDeleteButton,
  Datagrid,
  DateField,
  List,
  Pagination,
  ReferenceField,
  TextField
  // useRecordContext
} from 'react-admin';

import PageTitle from '../PageTitle';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  listHeader: {
    fontSize: '1.25rem',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: '400',
    color: '#005362',
    margin: 'inherit',
    lineHeight: '1.6',
    letterSpacing: '0.0075em'
  },
  link: {
    color: '#028090',
    textDecoration: 'none'
  }
});

const BulkActionButtons = props => (
  <>
    <BulkDeleteButton {...props} />
  </>
);

const ChatList = props => {
  const classes = useStyles();

  return (
    <>
      <h6 className={classes.listHeader}>Chat Messages</h6>

      <List
        {...props}
        title={<PageTitle />}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
        perPage={25}
        bulkActionButtons={<BulkActionButtons />}
      >
        <Datagrid rowClick='edit'>
          <DateField label='Date' source='createdAt' showTime />
          <ReferenceField source='from' reference='users' link='show'>
            <TextField className={classes.link} source='username' />
          </ReferenceField>

          <TextField source='body' />
        </Datagrid>
      </List>
    </>
  );
};

export default ChatList;
