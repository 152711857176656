import { fetchUtils } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';

import { baseApiUrl } from '../config/api-url.config';
import cloudinary from '../services/cloudinary';

const { config, transformations } = cloudinary;

/* eslint-disable no-unused-vars */
const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  const token = localStorage.getItem('auth');

  options.headers.set('x-auth-token', token);

  return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(`${baseApiUrl}api`, httpClient);

const customDataProvider = {
  ...dataProvider,
  create: async (resource, params) => {
    if (resource !== 'ads' || !params.data.image) {
      return dataProvider.create(resource, params);
    }

    const { data } = params;

    const { campaign, client, endDate, linkURL, spendTotal, startDate } = data;

    const filePrefix = client
      .toLowerCase()
      .trim()
      .replace(/[\s]/g, '-')
      .replace(/[.]/g, '');
    console.log('filePrefix: ', filePrefix);

    return convertFileToBase64(data.image)
      .then(base64Image => cloudinary.uploadImage(base64Image, filePrefix))
      .then(({ cloudinaryPublicId }) =>
        dataProvider.create(resource, {
          ...params,
          data: {
            campaign,
            client,
            spendTotal,
            endDate,
            startDate,
            linkURL,
            imageURL: `${config.base_url}/${transformations.ASSET_IMG_LARGE}/${cloudinaryPublicId}`,
            cloudinaryPublicId
          }
        })
      );
  }
};

const convertFileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  });

export default customDataProvider;
