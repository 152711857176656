import React from 'react';
import {
  // Button,
  DateField,
  Labeled,
  TextField,
  // useNotify,
  useRecordContext
  // useUpdate
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import CommentDelete from './CommentDelete';

// import { removeComment } from '../../util/comments';

const useStyles = makeStyles({
  list: {
    display: 'grid',
    gridTemplateRows: 'auto',
    rowGap: '0.5rem',
    paddingTop: '0.5rem',
    paddingBottom: '1rem'
  },
  deleteBtn: {
    color: '#f44336',

    '&:hover': { backgroundColor: 'rgba(244, 67, 54, 0.12)' }
  },
  comment: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 3.5fr 1fr',
    alignContent: 'center',
    gap: '1rem',
    minWidth: '14rem'
  },
  field: {
    padding: '4px 0'
  },
  msg: {
    textTransform: 'uppercase'
  }
});

// const DeleteButton = ({ record, commentId }) => {
//   const notify = useNotify();
//   const classes = useStyles();
//   // console.log('commentId: ', commentId);

//   let diff = {};
//   diff.comments = removeComment(commentId, record.comments);

//   const handleClick = () => {
//     update();
//   };

//   // useUpdate to send update
//   const [update, { loading }] = useUpdate('posts', record.id, diff, record, {
//     onSuccess: ({ data }) => notify('Comment successfully deleted'),
//     onFailure: error =>
//       notify(`Comment deletion error: ${error.message}`, 'warning')
//   });

//   return (
//     <Button
//       className={classes.deleteBtn}
//       label={'Delete Comment'}
//       disabled={loading}
//       onClick={handleClick}
//     />
//   );
// };

const CommentItem = ({ comment, record }) => {
  // console.log('comment id: ', comment._id);
  const classes = useStyles();
  return (
    <div className={classes.comment}>
      <DateField
        className={classes.field}
        record={comment}
        source='date'
        options={{
          weekday: 'short',
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }}
      />

      <TextField className={classes.field} record={comment} source='author' />
      <TextField className={classes.field} record={comment} source='text' />

      <CommentDelete record={record} commentId={comment._id} />
      {/* <DeleteButton record={record} commentId={comment._id} /> */}
    </div>
  );
};

const renderComments = record => {
  const { comments } = record;

  if (!comments) return;

  const commentItems = comments.map(comment => {
    return (
      <div key={comment._id}>
        <CommentItem comment={comment} record={record} />
        {comment.children?.length > 0 && renderComments(comment.children)}
      </div>
    );
  });
  return commentItems;
};

const PostCommentsEdit = props => {
  const record = useRecordContext(props);
  const classes = useStyles();
  // console.log('comments', record.comments);

  return (
    <div className={classes.list}>
      {record.comments?.length > 0 ? (
        renderComments(record)
      ) : (
        <Labeled className={classes.msg} label='no comments found'></Labeled>
      )}
    </div>
  );
};

export default PostCommentsEdit;
