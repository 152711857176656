import React from 'react';
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  Pagination
} from 'react-admin';

import PageTitle from '../PageTitle';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  listHeader: {
    fontSize: '1.25rem',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: '400',
    color: '#005362',
    margin: 'inherit',
    lineHeight: '1.6',
    letterSpacing: '0.0075em'
  },
  link: {
    color: '#028090',
    textDecoration: 'none'
  }
});

const PaymentsList = props => {
  const classes = useStyles();

  return (
    <>
      <h6 className={classes.listHeader}>Reward Payments</h6>

      <List
        {...props}
        title={<PageTitle />}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
        perPage={25}
      >
        <Datagrid rowClick='show'>
          <DateField label='Batch Created' source='createdAt' showTime />

          <FunctionField
            className={classes.link}
            label='Status'
            render={record => (record.finished ? 'Finished' : 'Pending')}
          />

          <FunctionField
            className={''}
            label='Total'
            render={record => record.payments.length}
          />
          <FunctionField
            className={''}
            label='Paid'
            render={record => record.result.successes}
          />
          <FunctionField
            className={''}
            label='Failed'
            render={record => record.result.failures}
          />
        </Datagrid>
      </List>
    </>
  );
};

export default PaymentsList;
