import React from 'react';
import {
  DateField,
  Edit,
  ListButton,
  ReferenceField,
  RefreshButton,
  SelectInput,
  ShowButton,
  SimpleForm,
  TextField,
  TopToolbar
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import PageTitle from '../PageTitle';
import ResponseViewTitle from './ResponseViewTitle';

const useStyles = makeStyles({
  toolbar: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  btn: { marginLeft: '0.5rem' },
  link: {
    color: '#028090',
    textDecoration: 'none'
  }
});

const ResponseEditActions = ({ basePath, data }) => {
  const classes = useStyles();
  return (
    <TopToolbar className={classes.toolbar}>
      <ResponseViewTitle view={'Edit Response'} record={data} />
      <div>
        <ShowButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} className={classes.btn} />
        <RefreshButton className={classes.btn} />
      </div>
    </TopToolbar>
  );
};

const ResponseEdit = props => {
  const classes = useStyles();

  return (
    <Edit
      title={<PageTitle {...props} />}
      actions={<ResponseEditActions />}
      {...props}
    >
      <SimpleForm>
        <DateField
          label='Date'
          source='createdAt'
          options={{
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          }}
        />
        <ReferenceField source='challenge' reference='challenges' link='show'>
          <TextField className={classes.link} source='title' />
        </ReferenceField>

        <ReferenceField
          label='User'
          source='user'
          reference='users'
          link='show'
        >
          <TextField className={classes.link} source='username' />
        </ReferenceField>

        <SelectInput
          source='status'
          choices={[
            { id: 'review', name: 'Review' },
            { id: 'accepted', name: 'Accepted' },
            { id: 'rejected', name: 'Rejected' }
          ]}
        />
      </SimpleForm>
    </Edit>
  );
};

export default ResponseEdit;
