import React from 'react';
import {
  DateField,
  EditButton,
  FunctionField,
  ListButton,
  NumberField,
  ReferenceField,
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  TopToolbar
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import PageTitle from '../PageTitle';
import ActivityBoardViewTitle from './ActivityBoardViewTitle';
import ActivityBoardScores from './ActivityBoardScores';
import CreateTipGroupButton from './CreateTipGroupButton';

const useStyles = makeStyles({
  toolbar: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  btn: { marginLeft: '0.5rem' },
  btnGroup: {
    display: 'flex'
  },
  link: {
    color: '#028090',
    textDecoration: 'none'
  },
  processed: {
    textTransform: 'uppercase'
  }
});

const ActivityBoardShowActions = ({ basePath, data }) => {
  const classes = useStyles();
  return (
    <TopToolbar className={classes.toolbar}>
      <ActivityBoardViewTitle view={'Show Activity Board'} record={data} />
      <span className={classes.btnGroup}>
        <CreateTipGroupButton basePath={basePath} record={data} />
        <EditButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} className={classes.btn} />
      </span>
    </TopToolbar>
  );
};

const ActivityBoardShow = props => {
  const classes = useStyles();

  return (
    <Show
      title={<PageTitle {...props} />}
      actions={<ActivityBoardShowActions />}
      {...props}
    >
      <TabbedShowLayout>
        <Tab label='summary'>
          <DateField
            label='Date'
            source='date'
            options={{
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            }}
          />
          <NumberField label='Prize Fund' source='prizeFund' />

          <NumberField label='Total Score' source='totalScore' />

          <FunctionField
            label='User Count'
            render={record => <span>{record.scores.length}</span>}
          />

          <FunctionField
            label='Processed'
            render={record => (
              <span className={classes.processed}>
                {record.processed ? 'yes' : 'no'}
              </span>
            )}
          />
          <ReferenceField
            label='Tip Group'
            source='tipGroup'
            reference='tipgroups'
            link='show'
          >
            <TextField className={classes.link} source='createdAt' />
          </ReferenceField>
        </Tab>
        <Tab label='scores'>
          <ActivityBoardScores />
        </Tab>
        <Tab label='data'>
          <FunctionField
            label='Data'
            render={record => <pre>{JSON.stringify(record, null, 2)}</pre>}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default ActivityBoardShow;
