import React from 'react';
import {
  DateField,
  Labeled,
  ReferenceField,
  TextField,
  useRecordContext
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  list: {
    display: 'grid',
    gridTemplateRows: 'auto',
    rowGap: '0.5rem',
    paddingTop: '0.5rem',
    paddingBottom: '1rem'
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 4.5fr',
    columnGap: '1rem',
    minWidth: '22rem',
    alignItems: 'baseline',
    fontSize: '0.875rem',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: '400',
    lineHeight: '1.43',
    letterSpacing: '0.01071em'
  },
  comment: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 4.5fr',
    gap: '1rem',
    minWidth: '14rem'
  },
  link: {
    color: '#028090',
    textDecoration: 'none'
  },
  msg: {
    textTransform: 'uppercase'
  }
});

const CommentItem = ({ comment }) => {
  const classes = useStyles();
  return (
    <div className={classes.comment}>
      <DateField
        label='Date'
        record={comment}
        source='createdAt'
        options={{
          weekday: 'short',
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }}
      />
      <ReferenceField
        record={comment}
        source='user'
        reference='users'
        link='show'
      >
        <TextField className={classes.link} source='username' />
      </ReferenceField>

      <TextField record={comment} source='text' />
    </div>
  );
};

const renderComments = comments => {
  const commentItems = comments.map(comment => {
    return (
      <span key={comment._id}>
        <CommentItem comment={comment} />
        {comment.children?.length > 0 && renderComments(comment.children)}
      </span>
    );
  });
  return commentItems;
};

const LessonCommentList = props => {
  const { comments } = useRecordContext(props);
  const classes = useStyles();

  return (
    <div className={classes.list}>
      <div className={classes.header}>
        <span>Date</span>
        <span>Author</span>
        <span>Text</span>
      </div>

      {comments?.length > 0 ? (
        renderComments(comments)
      ) : (
        <Labeled className={classes.msg} label='no comments found'></Labeled>
      )}
    </div>
  );
};

export default LessonCommentList;
