import React from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  FilterButton,
  FunctionField,
  List,
  Pagination,
  BooleanInput,
  ShowButton,
  TextField,
  TopToolbar
} from 'react-admin';

import PageTitle from '../PageTitle';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  listHeader: {
    fontSize: '1.25rem',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: '400',
    color: '#005362',
    margin: 'inherit',
    lineHeight: '1.6',
    letterSpacing: '0.0075em'
  },
  link: {
    color: '#028090',
    textDecoration: 'none'
  },
  status: {
    textTransform: 'uppercase'
  },
  boolean: {
    '& span': {
      '& svg[data-testid="false"]': {
        color: 'red'
      },
      '& svg[data-testid="true"]': {
        color: 'green'
      }
    }
  }
});

const TipGroupListActions = props => (
  <TopToolbar>
    <FilterButton />
  </TopToolbar>
);

const tipGroupFilters = [
  <BooleanInput source='submitted' label='Submitted' />,
  <BooleanInput source='finished' label='Finished' />
];

const TipGroupList = props => {
  const classes = useStyles();

  return (
    <>
      <h6 className={classes.listHeader}>Tip Groups</h6>

      <List
        {...props}
        filters={tipGroupFilters}
        actions={<TipGroupListActions />}
        title={<PageTitle />}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
      >
        <Datagrid rowClick='show'>
          <DateField
            label='Date'
            source='createdAt'
            options={{
              year: 'numeric',
              month: 'short',
              day: 'numeric'
            }}
          />

          <TextField source='targetType' className={classes.status} />

          <FunctionField
            label='Tip Count'
            render={record => record.tips.length}
          />

          <BooleanField source='submitted' className={classes.boolean} />

          <BooleanField source='finished' className={classes.boolean} />

          <ShowButton basePath='/tipgroups' label='Show' />
        </Datagrid>
      </List>
    </>
  );
};

export default TipGroupList;
