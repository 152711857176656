import PostIcon from '@material-ui/icons/Book';

import PostEdit from './PostEdit';
import PostList from './PostList';
import PostShow from './PostShow';

const resource = {
  list: PostList,
  show: PostShow,
  edit: PostEdit,
  icon: PostIcon
};

export default resource;
