import React from 'react';
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  NumberField,
  Pagination,
  ReferenceField,
  TextField,
  TopToolbar
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import PageTitle from '../PageTitle';
import MonthlyBudget from './MonthlyBudget';

const useStyles = makeStyles({
  listHeader: {
    fontSize: '1.25rem',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: '400',
    color: '#005362',
    margin: 'inherit',
    lineHeight: '1.6',
    letterSpacing: '0.0075em'
  },
  monthlyBudgetWrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    borderRadius: ' 4px',
    paddingLeft: '1rem',

    '& label': {
      color: '#3f51b5'
    }
  },
  monthlyBudget: {
    fontWeight: '400'
  },
  editBtn: {
    position: 'absolute',
    top: '0',
    right: '0.5rem'
  },
  link: {
    color: '#028090',
    textDecoration: 'none'
  },
  processed: {
    textTransform: 'uppercase'
  }
});

const ListActions = () => {
  return (
    <TopToolbar>
      <MonthlyBudget />
    </TopToolbar>
  );
};

const ActivityBoardList = props => {
  const classes = useStyles();

  return (
    <>
      <h6 className={classes.listHeader}>Activity Score Boards List</h6>

      <List
        {...props}
        actions={<ListActions />}
        title={<PageTitle />}
        // exporter={false}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
        perPage={25}
      >
        <Datagrid rowClick='show'>
          <DateField
            label='Date'
            source='date'
            options={{
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            }}
          />

          <NumberField label='Total Score' source='totalScore' />

          <FunctionField
            label='User Count'
            render={record => <span>{record.scores.length}</span>}
          />

          <FunctionField
            label='Processed'
            render={record => (
              <span className={classes.processed}>
                {record.processed ? 'yes' : 'no'}
              </span>
            )}
          />
          <ReferenceField
            label='Tip Group'
            source='tipGroup'
            reference='tipgroups'
            link='show'
          >
            <TextField className={classes.link} source='id' />
          </ReferenceField>
        </Datagrid>
      </List>
    </>
  );
};

export default ActivityBoardList;
