import Assignment from '@material-ui/icons/Assignment';

import ChallengeCreate from './ChallengeCreate';
import ChallengeEdit from './ChallengeEdit';
import ChallengeList from './ChallengeList';
import ChallengeShow from './ChallengeShow';

const resource = {
  list: ChallengeList,
  show: ChallengeShow,
  create: ChallengeCreate,
  edit: ChallengeEdit,
  icon: Assignment
};

export default resource;
