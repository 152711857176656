import { useEffect, useState } from 'react';
import {
  Create,
  FormDataConsumer,
  FunctionField,
  Labeled,
  ListButton,
  NullableBooleanInput,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  TopToolbar
} from 'react-admin';
import { useLocation } from 'react-router';

import useFetchUsernameByUserId from './useFetchUsernameByUserId';

import PageTitle from '../PageTitle';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  topToolbar: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  btn: { marginLeft: '0.5rem' },
  formHeader: {
    fontSize: '1.25rem',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: '400',
    color: '#005362',
    margin: 'inherit',
    lineHeight: '1.6',
    letterSpacing: '0.0075em'
  },
  labeled: {
    width: '100%',
    marginBottom: '24px',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    '& label': {
      paddingLeft: '12px'
    }
  },
  usernameField: {
    paddingLeft: '12px',
    display: 'inline-flex',
    position: 'relative',
    fontSize: '1rem',
    boxSizing: 'border-box',
    alignItems: 'center',
    lineHeight: '1.1876em',
    letterSpacing: ' 0.00938em'
  },
  noUsername: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem'
  },
  notFound: {
    color: '#f44336',
    textTransform: 'uppercase'
  }
});

const UsernameField = ({ record, setIsValidID }) => {
  const classes = useStyles();

  const username = useFetchUsernameByUserId(record.user);

  useEffect(() => {
    const isValid =
      username !== null &&
      username !== '' &&
      username !== 'User ID invalid format';

    setIsValidID(isValid);
  }, [setIsValidID, record.user, username]);

  return (
    <Labeled label='Username' className={classes.labeled}>
      <span className={classes.usernameField}>
        {username === 'User ID invalid format' ? (
          <span className={classes.notFound}>{username}</span>
        ) : username ? (
          username
        ) : username === '' ? (
          <span className={classes.notFound}>user not found</span>
        ) : (
          <span className={classes.noUsername}>
            Autofilled based on User ID
          </span>
        )}
      </span>
    </Labeled>
  );
};

const RestrictedUserActions = ({ basePath }) => {
  const classes = useStyles();
  return (
    <TopToolbar className={classes.topToolbar}>
      <h6 className={classes.formHeader}>Add User to Restricted List</h6>
      <ListButton basePath={basePath} className={classes.btn} />
    </TopToolbar>
  );
};

const RestrictedUserToolbar = props => {
  const { isValidID, ...rest } = props;

  return (
    <Toolbar {...rest} alwaysEnableSaveButton>
      <SaveButton disabled={!isValidID} />
    </Toolbar>
  );
};

const RestrictedUserCreate = props => {
  const location = useLocation();

  const [isValidID, setIsValidID] = useState(null);

  const user =
    location.state && location.state.record ? location.state.record.user : '';
  return (
    <Create
      {...props}
      title={<PageTitle />}
      actions={<RestrictedUserActions />}
    >
      <SimpleForm
        initialValues={{ user }}
        toolbar={<RestrictedUserToolbar isValidID={isValidID} />}
        redirect='list'
      >
        <TextInput
          label='User ID'
          source='user'
          validate={required()}
          resettable
          fullWidth
        />
        <FormDataConsumer>
          {({ formData }) => {
            return (
              <FunctionField
                label='Username'
                record={formData}
                render={record => (
                  <UsernameField record={record} setIsValidID={setIsValidID} />
                )}
              />
            );
          }}
        </FormDataConsumer>

        <NullableBooleanInput source='permanent' initialValue={false} />

        <TextInput source='notes' multiline resettable fullWidth />
      </SimpleForm>
    </Create>
  );
};
export default RestrictedUserCreate;
