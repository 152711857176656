/* eslint-disable import/no-anonymous-default-export */
import { baseApiUrl } from '../config/api-url.config';

// console.log('baseApiUrl: ', baseApiUrl);

export default {
  // called when the user attempts to log in
  login: ({ username, password }) => {
    const request = new Request(`${baseApiUrl}api/auth/`, {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' })
    });

    return (
      fetch(request)
        .then(response => {
          if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
          }
          return response.json();
        })

        // save token and user separately in localStorage
        .then(res => {
          // console.log('token: ', rest.token);
          localStorage.setItem('auth', res.token);
          localStorage.setItem('user', JSON.stringify(res.user));
        })
        .catch(error => {
          console.log('error: ', error);
          throw new Error(error);
        })
    );
  },

  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem('auth');
    localStorage.removeItem('user');
    return Promise.resolve();
  },

  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('auth');
      localStorage.removeItem('user');
      return Promise.reject();
    }
    return Promise.resolve();
  },

  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem('auth') ? Promise.resolve() : Promise.reject();
  },

  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),

  // called on mount for navbar and for useGetIdentity
  getIdentity: () => {
    try {
      const {
        user: { id, name, avatar }
      } = JSON.parse(localStorage.getItem('user'));

      return Promise.resolve({
        id,
        fullName: name,
        avatar
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }
};
