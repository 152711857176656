import React from 'react';
import {
  BooleanInput,
  DeleteButton,
  Edit,
  ListButton,
  RadioButtonGroupInput,
  RefreshButton,
  SaveButton,
  ShowButton,
  SimpleForm,
  TextInput,
  Toolbar,
  TopToolbar
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import PageTitle from '../PageTitle';
import RestrictedUserButton from '../RestrictedUsers/RestrictedUserButton';

import UserViewTitle from './UserViewTitle';

const useStyles = makeStyles({
  toolbar: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  btn: { marginLeft: '0.5rem' },
  address: { width: '350px' }
});

const UserEditActions = ({ basePath, data }) => {
  const classes = useStyles();
  return (
    <TopToolbar className={classes.toolbar}>
      <UserViewTitle view={'Edit User'} record={data} />
      <div>
        <ShowButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} className={classes.btn} />
        <RefreshButton className={classes.btn} />
      </div>
    </TopToolbar>
  );
};

const UserEditToolbar = props => {
  console.log('props: ', props);
  const classes = useStyles();

  return (
    <Toolbar className={classes.toolbar} {...props}>
      <SaveButton disabled={props.pristine} />
      <RestrictedUserButton
        style={{ marginLeft: 'auto' }}
        basePath={props.basePath}
        record={props.record}
      />
      <DeleteButton basePath={props.basePath} variant='text' />
    </Toolbar>
  );
};

const UserEdit = props => {
  const classes = useStyles();

  return (
    <Edit
      title={<PageTitle />}
      actions={<UserEditActions />}
      mutationMode='pessimistic'
      {...props}
    >
      <SimpleForm toolbar={<UserEditToolbar />} redirect='show'>
        <TextInput disabled source='id' />
        <TextInput source='username' />
        <TextInput source='email' />
        <TextInput className={classes.address} source='paymentAccount' />
        <RadioButtonGroupInput
          source='userRole'
          choices={[
            { id: 'member', name: 'Member' },
            { id: 'admin', name: 'Admin' }
          ]}
        />
        <BooleanInput label={'User Verified'} source='verified' />
      </SimpleForm>
    </Edit>
  );
};

export default UserEdit;
