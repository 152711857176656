import * as React from 'react';
import { SelectInput, useListContext } from 'react-admin';
import { Form } from 'react-final-form';

import { Box, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/CancelOutlined';

const useStyles = makeStyles({
  form: {
    marginRight: 'auto'
  },
  input: {
    '& .MuiFormHelperText-root': {
      display: 'none'
    }
  }
});

const ChallengeFilterForm = () => {
  const classes = useStyles();
  const { data, displayedFilters, filterValues, setFilters } = useListContext();

  if (!displayedFilters.main) return null;

  let filterChoices = [];

  Object.values(data).forEach(item => {
    if (filterChoices.findIndex(x => x.id === item.challenge) === -1) {
      filterChoices.push({ id: item.challenge, name: item.challengeTitle });
    }
  });

  const onSubmit = () => {};

  const setFilter = e => {
    setFilters({ challenge: e.target.value });
  };

  const resetFilter = () => {
    setFilters({});
  };

  return (
    <Form onSubmit={onSubmit} initialValues={filterValues}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <Box display='flex' alignItems='center' mb={1}>
            {/* <Box component='span' mb={1.5}> */}
            <IconButton variant='outlined' onClick={resetFilter}>
              <CancelIcon />
            </IconButton>
            {/* </Box> */}
            <Box component='span' mr={2}>
              <SelectInput
                source='challenge'
                choices={filterChoices}
                onChange={setFilter}
                className={classes.input}
              />
            </Box>
          </Box>
        </form>
      )}
    </Form>
  );
};

export default ChallengeFilterForm;
