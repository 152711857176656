import React from 'react';
import {
  DateField,
  Labeled,
  ReferenceField,
  TextField,
  useRecordContext
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import CommentDelete from './CommentDelete';

const useStyles = makeStyles({
  list: {
    display: 'grid',
    gridTemplateRows: 'auto',
    rowGap: '0.5rem',
    paddingTop: '0.5rem',
    paddingBottom: '1rem'
  },
  deleteBtn: {
    color: '#f44336',

    '&:hover': { backgroundColor: 'rgba(244, 67, 54, 0.12)' }
  },
  comment: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 3.5fr 1fr',
    alignContent: 'center',
    gap: '1rem',
    minWidth: '14rem'
  },
  field: {
    padding: '4px 0'
  },
  msg: {
    textTransform: 'uppercase'
  },
  link: {
    color: '#028090',
    textDecoration: 'none'
  }
});

const CommentItem = ({ comment, record }) => {
  const classes = useStyles();

  return (
    <div className={classes.comment}>
      <DateField
        className={classes.field}
        record={comment}
        source='createdAt'
        options={{
          weekday: 'short',
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }}
      />

      <ReferenceField
        label='User'
        record={comment}
        source='user'
        reference='users'
        link='show'
        className={classes.field}
      >
        <TextField className={classes.link} source='username' />
      </ReferenceField>

      <TextField className={classes.field} record={comment} source='text' />

      <CommentDelete record={record} commentId={comment._id} />
    </div>
  );
};

const renderComments = record => {
  const { comments } = record;

  if (!comments) return;

  const commentItems = comments.map(comment => {
    return (
      <div key={comment._id}>
        <CommentItem comment={comment} record={record} />
        {comment.children?.length > 0 && renderComments(comment.children)}
      </div>
    );
  });
  return commentItems;
};

const LessonCommentsEdit = props => {
  const record = useRecordContext(props);
  const classes = useStyles();

  return (
    <div className={classes.list}>
      {record.comments?.length > 0 ? (
        renderComments(record)
      ) : (
        <Labeled className={classes.msg} label='no comments found'></Labeled>
      )}
    </div>
  );
};

export default LessonCommentsEdit;
