import React from 'react';
import {
  CreateButton,
  Datagrid,
  DateField,
  FunctionField,
  List,
  NumberField,
  Pagination,
  ShowButton,
  TextField,
  TopToolbar
} from 'react-admin';

import PageTitle from '../PageTitle';

import { makeStyles } from '@material-ui/core/styles';
import Done from '@material-ui/icons/Done';
import Clear from '@material-ui/icons/Clear';

const useStyles = makeStyles({
  listHeader: {
    fontSize: '1.25rem',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: '400',
    color: '#005362',
    margin: 'inherit',
    lineHeight: '1.6',
    letterSpacing: '0.0075em'
  },
  active: {
    color: 'green'
  },
  inactive: {
    color: 'red'
  },
  center: {
    textAlign: 'center'
  }
});

const ListActions = () => (
  <TopToolbar>
    <CreateButton />
  </TopToolbar>
);

const AdList = props => {
  const classes = useStyles();

  return (
    <>
      <h6 className={classes.listHeader}>Banner Ads</h6>

      <List
        {...props}
        actions={<ListActions />}
        title={<PageTitle />}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
        perPage={25}
      >
        <Datagrid rowClick='show' isRowSelectable={() => false}>
          <FunctionField
            label='Active'
            render={record =>
              record.active ? (
                <Done className={classes.active} />
              ) : (
                <Clear className={classes.inactive} />
              )
            }
          />
          <TextField source='client' />
          <TextField source='campaign' />

          <NumberField source='impressions' cellClassName={classes.center} />
          <NumberField source='clicks' cellClassName={classes.center} />

          <DateField source='startDate' />
          <DateField source='endDate' />
          <ShowButton basePath='/ads' />
        </Datagrid>
      </List>
    </>
  );
};

export default AdList;
