import React from 'react';
import { Datagrid, DateField, List, Pagination, ShowButton } from 'react-admin';

import PageTitle from '../PageTitle';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  listHeader: {
    fontSize: '1.25rem',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: '400',
    color: '#005362',
    margin: 'inherit',
    lineHeight: '1.6',
    letterSpacing: '0.0075em'
  }
});

const LeaderboardList = props => {
  const classes = useStyles();

  return (
    <>
      <h6 className={classes.listHeader}>Leaderboard Batches</h6>

      <List
        {...props}
        title={<PageTitle />}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
      >
        <Datagrid isRowSelectable={() => false} rowClick='show'>
          <DateField label='Date' source='createdAt' showTime />

          <ShowButton basePath='/leaderboards' label='Show' />
        </Datagrid>
      </List>
    </>
  );
};

export default LeaderboardList;
