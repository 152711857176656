import LessonIcon from '@material-ui/icons/School';

import LessonEdit from './LessonEdit';
import LessonList from './LessonList';
import LessonShow from './LessonShow';

const resource = {
  list: LessonList,
  show: LessonShow,
  edit: LessonEdit,
  icon: LessonIcon
};

export default resource;
