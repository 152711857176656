import ChatIcon from '@material-ui/icons/Chat';

import ChatEdit from './ChatEdit';
import ChatList from './ChatList';

const resource = {
  list: ChatList,
  edit: ChatEdit,
  icon: ChatIcon
};

export default resource;
