import React from 'react';

import {
  List,
  BooleanField,
  DateField,
  Datagrid,
  EditButton,
  TextField
} from 'react-admin';

import PageTitle from '../PageTitle';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  listHeader: {
    fontSize: '1.25rem',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: '400',
    color: '#005362',
    margin: 'inherit',
    lineHeight: '1.6',
    letterSpacing: '0.0075em'
  }
});

const SettingsList = props => {
  const classes = useStyles();

  // Todo: settings expansion: determine settingValue type
  // render appropriate settingValue field: BooleanField, NumberField, or TextField

  return (
    <>
      <h6 className={classes.listHeader}>Settings</h6>

      <List {...props} title={<PageTitle />}>
        <Datagrid isRowSelectable={() => false} rowClick='edit'>
          <TextField source='label' />
          <TextField source='setting' />
          <BooleanField source='settingValue' />
          <DateField source='updatedAt' showTime />
          <EditButton basePath='/settings' label='Edit Setting' />
        </Datagrid>
      </List>
    </>
  );
};

export default SettingsList;
