import { useEffect, useState } from 'react';

const useCheckIsRestrictedUser = userId => {
  const [isRestrictedUser, setIsRestrictedUser] = useState(null);

  const url = userId ? `/api/restrictedusers/${userId}/check` : '';

  useEffect(() => {
    if (!url) return;

    const token = localStorage.getItem('auth');
    const headers = {
      'Content-Type': 'application/json',
      'x-auth-token': token
    };
    fetch(url, {
      method: 'GET',
      headers
    })
      .then(async res => {
        const data = await res.json();
        setIsRestrictedUser(data.isRestrictedUser);
      })
      .catch(e => {
        console.log('useCheckIsRestrictedUser error: ', e);
        setIsRestrictedUser(null);
      });
  }, [url]);

  return isRestrictedUser;
};

export default useCheckIsRestrictedUser;
