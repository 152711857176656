import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import PaymentList from './PaymentList';
import PaymentShow from './PaymentShow';

const resource = {
  list: PaymentList,
  show: PaymentShow,
  icon: AttachMoneyIcon
};

export default resource;
