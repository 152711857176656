import RewardIcon from '@material-ui/icons/EmojiEvents';

import RewardEdit from './RewardEdit';
import RewardList from './RewardList';
import RewardShow from './RewardShow';

const resource = {
  list: RewardList,
  show: RewardShow,
  edit: RewardEdit,
  icon: RewardIcon
};

export default resource;
