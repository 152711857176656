import React from 'react';
import {
  BooleanField,
  EditButton,
  DateField,
  FunctionField,
  ImageField,
  ListButton,
  NumberField,
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import PageTitle from '../PageTitle';
import LessonViewTitle from './LessonViewTitle';
import LessonCommentList from './LessonCommentList';
import LessonTipList from './LessonTipList';

const useStyles = makeStyles({
  toolbar: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  btn: { marginLeft: '0.5rem' },
  link: {
    color: '#028090',
    textDecoration: 'none'
  }
});

const LessonShowActions = ({ basePath, data }) => {
  const classes = useStyles();
  return (
    <TopToolbar className={classes.toolbar}>
      <LessonViewTitle view={'Show Lesson'} record={data} />
      <div>
        <EditButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} className={classes.btn} />
      </div>
    </TopToolbar>
  );
};

const LessonShow = props => {
  const classes = useStyles();

  return (
    <Show
      title={<PageTitle {...props} />}
      actions={<LessonShowActions />}
      {...props}
    >
      <TabbedShowLayout>
        <Tab label='summary'>
          <DateField label='Date' source='createdAt' showTime={true} />
          <ReferenceField
            label='User'
            source='user'
            reference='users'
            link='show'
          >
            <TextField className={classes.link} source='username' />
          </ReferenceField>
          <TextField source='title' />

          <NumberField source='viewsCount' />
          <NumberField source='commentCount' />
          <NumberField source='upvoteCount' />
          <BooleanField source='isTipGated' label='Tip Gated' />
        </Tab>

        <Tab label='content'>
          <ImageField source='image' title='lesson image' />
          <TextField source='title' />
          <TextField source='description' />
          <FunctionField
            label='Content'
            render={record => (
              <div dangerouslySetInnerHTML={{ __html: record.content }} />
            )}
          />
        </Tab>

        <Tab label='comments'>
          <LessonCommentList source='comments' />
        </Tab>

        <Tab label='tips'>
          <LessonTipList source='upvotes' />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default LessonShow;
