import { useState } from 'react';
import {
  Button,
  useListContext,
  useNotify,
  useRedirect,
  useUnselectAll
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import TipGroupIcon from '@material-ui/icons/MonetizationOn';

import { DEFAULT_LESSON_TIP_AMOUNT } from '../../config/constants';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  field: { width: '80px' },
  label: {
    position: 'absolute',
    left: '9px',
    fontSize: '0.75rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
  },
  input: { padding: '16px 0 4px 12px' }
});

const muiInput = 'MuiInputBase-input MuiInputBase-inputMarginDense';
const muiInputWrapper =
  'MuiInputBase-root MuiFilledInput-root MuiFilledInput-underline MuiInputBase-formControl MuiInputBase-marginDense MuiFilledInput-marginDense';

const CreateTipGroupButton = () => {
  const classes = useStyles();
  const { selectedIds } = useListContext();
  const notify = useNotify();
  const redirect = useRedirect();
  const unselectAll = useUnselectAll('lessons');
  const [amount, setAmount] = useState(DEFAULT_LESSON_TIP_AMOUNT);
  const [isProcessing, setIsProcessing] = useState(false);

  const token = localStorage.getItem('auth');
  const headers = {
    'Content-Type': 'application/json',
    'x-auth-token': token
  };

  const createTipGroup = () => {
    const groupData = {
      targetType: 'lessons',
      targets: selectedIds,
      amount: Number(amount)
    };
    console.log('groupData: ', groupData);
    setIsProcessing(true);
    fetch(`/api/tipgroups`, {
      method: 'POST',
      headers,
      body: JSON.stringify(groupData)
    })
      .then(async res => {
        const data = await res.json();
        console.log('data: ', data);
        notify(data.message, { type: data.success ? 'success' : 'warning' });
        unselectAll('lessons');
      })
      .catch(e => {
        notify('Error: created tip group failed', { type: 'warning' });
      })
      .finally(() => {
        setIsProcessing(false);
        redirect('/tipgroups');
      });
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.field}>
        <label
          className={classes.label}
          data-shrink='true'
          htmlFor='tipAmount'
          id='tipAmount-label'
        >
          <span>Tip Amount</span>
        </label>
        <div className={muiInputWrapper}>
          <input
            id='tipAmount'
            name='tipAmount'
            className={`${muiInput} ${classes.input}`}
            type='number'
            value={amount}
            step='0.1'
            min='0.1'
            onChange={e => setAmount(e.target.value)}
          />
        </div>
      </div>
      <Button
        label='Create Tip Group'
        onClick={createTipGroup}
        disabled={isProcessing || selectedIds.length === 0}
      >
        <TipGroupIcon />
      </Button>
    </div>
  );
};

export default CreateTipGroupButton;
