import React from 'react';
import { ReferenceField, TextField } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  title: {
    color: 'rgba(0, 0, 0, 0.55)',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: '400',
    lineHeight: '1.5',
    letterSpacing: '0.00938em'
  },
  action: { textTransform: 'uppercase', paddingRight: '1rem' },
  author: {
    fontStyle: 'italic',
    fontSize: '1rem',
    color: '#028090',
    textDecoration: 'none'
  },
  headline: { paddingLeft: '1rem' }
});

const LessonViewTitle = ({ view, record }) => {
  const classes = useStyles();
  let headline = '';

  if (record && record.description) {
    headline =
      record.description.length > 40
        ? `${record.description.slice(0, 40)}...`
        : record.description;
  }

  return (
    <span className={classes.title}>
      <span className={classes.action}>{view ? `${view}: ` : 'Lesson: '}</span>
      <span className={classes.author}>
        <ReferenceField
          record={record}
          source='user'
          reference='users'
          link='false'
        >
          <TextField className={classes.author} source='username' />
        </ReferenceField>
      </span>
      <span className={classes.headline}>"{headline}"</span>
    </span>
  );
};
export default LessonViewTitle;
