import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  title: {
    color: 'rgba(0, 0, 0, 0.55)',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: '400',
    lineHeight: '1.5',
    letterSpacing: '0.00938em'
  },
  action: { textTransform: 'uppercase', paddingRight: '1rem' },
  companyName: { fontStyle: 'italic' },
  headline: { paddingLeft: '1rem' }
});

const ChallengeViewTitle = ({ view, record }) => {
  const classes = useStyles();

  const headline = !record
    ? ''
    : record.title.length > 40
    ? `${record.title.slice(0, 40)}...`
    : record.title;

  return (
    <span className={classes.title}>
      <span className={classes.action}>{view ? `${view} ` : 'Challenge'}</span>
      <span className={classes.companyName}>
        {record?.companyName ? `${record.companyName}:` : ''}
      </span>
      <span className={classes.headline}>"{headline}"</span>
    </span>
  );
};
export default ChallengeViewTitle;
