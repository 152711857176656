import { useEffect, useState } from 'react';

const useFetchUsernameByUserId = userId => {
  const [username, setUsername] = useState(null);

  useEffect(() => {
    if (!userId) {
      setUsername(null);
      return;
    }

    if (userId.length !== 24) {
      setUsername('User ID invalid format');
      return;
    }

    const url = `/api/users/${userId}/username`;

    const token = localStorage.getItem('auth');
    const headers = {
      'Content-Type': 'application/json',
      'x-auth-token': token
    };
    fetch(url, {
      method: 'GET',
      headers
    })
      .then(async res => {
        const data = await res.json();
        setUsername(data.username);
      })
      .catch(e => {
        console.log('useFetchUsernameByUserId error: ', e);
        setUsername('');
      });
  }, [userId]);

  return username;
};

export default useFetchUsernameByUserId;
