import {
  BooleanInput,
  DateField,
  Edit,
  ListButton,
  RefreshButton,
  SimpleForm,
  TextField,
  TopToolbar
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import PageTitle from '../PageTitle';
import SettingsViewTitle from './SettingsViewTitle';

const useStyles = makeStyles({
  toolbar: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  btn: { marginLeft: '0.5rem' },
  root: { color: 'hsl(4, 90%, 58%) ' }
});

const SettingsEditActions = ({ basePath, data }) => {
  const classes = useStyles();

  return (
    <TopToolbar className={classes.toolbar}>
      <SettingsViewTitle view={'Settings'} record={data} />
      <div>
        <ListButton basePath={basePath} className={classes.btn} />
        <RefreshButton className={classes.btn} />
      </div>
    </TopToolbar>
  );
};

const SettingsEdit = props => {
  const classes = useStyles();

  return (
    <Edit
      title={<PageTitle />}
      actions={<SettingsEditActions />}
      mutationMode='pessimistic'
      {...props}
    >
      <SimpleForm redirect='list'>
        <TextField source='label' />
        <BooleanInput
          id='setting-toggle'
          source='settingValue'
          className={classes.root}
          label={false}
        />
        <DateField label={'Last Updated At:'} source='updatedAt' showTime />
      </SimpleForm>
    </Edit>
  );
};

export default SettingsEdit;
