import React from 'react';
import {
  DateField,
  DeleteButton,
  EditButton,
  FunctionField,
  ImageField,
  Labeled,
  ListButton,
  NumberField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  UrlField
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import Done from '@material-ui/icons/Done';
import Clear from '@material-ui/icons/Clear';

import PageTitle from '../PageTitle';
import AdViewTitle from './AdViewTitle';

const useStyles = makeStyles({
  toolbar: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  btn: { marginLeft: '0.5rem' },
  link: {
    color: '#028090',
    textDecoration: 'none'
  },
  active: {
    color: 'green'
  },
  inactive: {
    color: 'red'
  },
  fieldGroup: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '10rem 10rem 10rem',
    gridTemplateRows: 'auto',
    gap: '2rem',
    marginBottom: '0.5rem'
  }
});

const AdShowActions = ({ basePath, data }) => {
  const classes = useStyles();
  return (
    <TopToolbar className={classes.toolbar}>
      <AdViewTitle view={'Show Ad'} record={data} />
      <div>
        <EditButton basePath={basePath} record={data} />
        <DeleteButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} className={classes.btn} />
      </div>
    </TopToolbar>
  );
};

const AdShow = props => {
  const classes = useStyles();

  return (
    <Show
      title={<PageTitle {...props} />}
      actions={<AdShowActions />}
      {...props}
    >
      <TabbedShowLayout>
        <Tab label='summary'>
          <div className={classes.fieldGroup}>
            <Labeled label='Client'>
              <TextField source='client' />
            </Labeled>
            <Labeled label='Campaign'>
              <TextField label='Campaign' source='campaign' />
            </Labeled>

            <Labeled label='Spend Total'>
              <NumberField source='spendTotal' />
            </Labeled>
          </div>

          <div className={classes.fieldGroup}>
            <Labeled label='Start Date'>
              <DateField
                showTime
                source='startDate'
                formClassName={classes.leftInput}
                className={classes.inlineInput}
              />
            </Labeled>

            <Labeled label='End Date'>
              <DateField
                showTime
                source='endDate'
                formClassName={classes.rightInput}
                className={classes.inlineInput}
              />
            </Labeled>

            <Labeled label='Created At'>
              <DateField showTime source='createdAt' />
            </Labeled>
          </div>

          <div className={classes.fieldGroup}>
            <Labeled label='Active'>
              <FunctionField
                render={record =>
                  record.active ? (
                    <Done className={classes.active} />
                  ) : (
                    <Clear className={classes.inactive} />
                  )
                }
              />
            </Labeled>

            <Labeled label='Impressions'>
              <NumberField source='impressions' />
            </Labeled>

            <Labeled label='Clicks'>
              <NumberField source='clicks' />
            </Labeled>

            <Labeled label='Last Impression Date'>
              <DateField showTime source='lastImpressionDate' />
            </Labeled>
          </div>
        </Tab>

        <Tab label='asset'>
          <ImageField label='Ad Image' source='image' title='ad image' />

          <TextField label='Ad Image URL' source='imageURL' />

          <UrlField label='Ad Link URL' source='linkURL' />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default AdShow;
