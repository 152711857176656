import * as React from 'react';
import {
  Create,
  DateTimeInput,
  ImageField,
  ImageInput,
  ListButton,
  // NumberInput,
  RefreshButton,
  required,
  SimpleForm,
  TextInput,
  TopToolbar
} from 'react-admin';

import PageTitle from '../PageTitle';
import AdViewTitle from './AdViewTitle';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  topToolbar: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  btn: { marginLeft: '0.5rem' },
  leftInput: {
    display: 'inline-block',
    width: '48%',
    marginRight: '1.5rem'
  },
  rightInput: {
    display: 'inline-block',
    width: '48%'
  },
  inlineInput: {
    width: '100%'
  },
  singleInput: {
    width: '48%'
  },
  urlInput: {
    width: '100%'
  }
});

const AdCreateActions = ({ basePath, data }) => {
  const classes = useStyles();
  return (
    <TopToolbar className={classes.topToolbar}>
      <AdViewTitle view={'Create Ad'} record={data} />
      <div>
        <ListButton basePath={basePath} className={classes.btn} />
        <RefreshButton className={classes.btn} />
      </div>
    </TopToolbar>
  );
};

const validateRequired = required();

const AdCreate = props => {
  const classes = useStyles();

  return (
    <Create
      actions={<AdCreateActions />}
      title={<PageTitle {...props} />}
      {...props}
    >
      <SimpleForm redirect='list'>
        <TextInput
          formClassName={classes.leftInput}
          className={classes.inlineInput}
          source='client'
          validate={validateRequired}
        />
        <TextInput
          formClassName={classes.rightInput}
          className={classes.inlineInput}
          source='campaign'
        />

        {/* <NumberInput
          className={classes.singleInput}
          source='spendTotal'
          validate={validateRequired}
        /> */}

        <DateTimeInput
          formClassName={classes.leftInput}
          className={classes.inlineInput}
          source='startDate'
          validate={validateRequired}
        />
        <DateTimeInput
          formClassName={classes.rightInput}
          className={classes.inlineInput}
          source='endDate'
          validate={validateRequired}
        />

        <TextInput
          className={classes.urlInput}
          label='Ad Link URL'
          source='linkURL'
          validate={validateRequired}
        />

        <TextInput
          className={classes.urlInput}
          label='Ad image URL'
          source='imageURL'
        />

        <ImageInput
          source='image'
          label='Ad Image'
          accept='image/*'
          placeholder={<p>Drop your image here</p>}
        >
          <ImageField source='src' title='title' />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};
export default AdCreate;
