import React from 'react';
import {
  DateField,
  FunctionField,
  ListButton,
  Show,
  Tab,
  TabbedShowLayout,
  TopToolbar
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import PageTitle from '../PageTitle';
import PaymentViewTitle from './PaymentViewTitle';
import RewardList from './RewardList';

const useStyles = makeStyles({
  toolbar: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  btn: { marginLeft: '0.5rem' },
  link: {
    color: '#028090',
    textDecoration: 'none'
  },
  status: {
    '& span': {
      textTransform: 'uppercase'
    },
    '& label': {
      '& span': {
        textTransform: 'capitalize'
      }
    }
  }
});

const PaymentShowActions = ({ basePath, data }) => {
  const classes = useStyles();
  return (
    <TopToolbar className={classes.toolbar}>
      <PaymentViewTitle view={'Show Payment Batch'} record={data} />
      <div>
        <ListButton basePath={basePath} className={classes.btn} />
      </div>
    </TopToolbar>
  );
};

const PaymentShow = props => {
  const classes = useStyles();

  return (
    <Show
      title={<PageTitle {...props} />}
      actions={<PaymentShowActions />}
      {...props}
    >
      <TabbedShowLayout>
        <Tab label='Summary'>
          <DateField label='Batch Created' source='createdAt' showTime />
          <FunctionField
            className={classes.status}
            label='Status'
            render={record => (record.finished ? 'Finished' : 'Pending')}
          />

          <FunctionField
            className={''}
            label='Total'
            render={record => record.payments.length}
          />
          <FunctionField
            className={''}
            label='Paid'
            render={record => record.result.successes}
          />
          <FunctionField
            className={''}
            label='Failed'
            render={record => record.result.failures}
          />
        </Tab>
        <Tab label='Payments'>
          <RewardList source='payments' />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default PaymentShow;
