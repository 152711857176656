import React from 'react';
import {
  BooleanInput,
  Edit,
  FormTab,
  ListButton,
  RefreshButton,
  ShowButton,
  TabbedForm,
  TextField,
  TopToolbar
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import PageTitle from '../PageTitle';
import PostViewTitle from '../Users/UserViewTitle';
import PostCommentsEdit from './PostCommentsEdit';

const useStyles = makeStyles({
  toolbar: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  btn: { marginLeft: '0.5rem' }
});

const PostEditActions = ({ basePath, data }) => {
  const classes = useStyles();
  return (
    <TopToolbar className={classes.toolbar}>
      <PostViewTitle view={'Edit Post'} record={data} />
      <div>
        <ShowButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} className={classes.btn} />
        <RefreshButton className={classes.btn} />
      </div>
    </TopToolbar>
  );
};

const PostEdit = props => {
  return (
    <Edit
      title={<PageTitle />}
      actions={<PostEditActions />}
      mutationMode='pessimistic'
      {...props}
    >
      <TabbedForm redirect='show'>
        <FormTab label='summary'>
          <TextField source='id' />
          <TextField source='author' />
          <TextField source='description' />
          <TextField source='story' />
          <BooleanInput label={'Tip Gated Post'} source='isGated' />
          <BooleanInput label={'Hidden from Feed'} source='hiddenFromFeed' />
        </FormTab>
        <FormTab label='comments'>
          <PostCommentsEdit source='comments' />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default PostEdit;
