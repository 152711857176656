import LocalActivity from '@material-ui/icons/LocalActivity';

import ActivityBoardEdit from './ActivityBoardEdit';
import ActivityBoardList from './ActivityBoardList';
import ActivityBoardShow from './ActivityBoardShow';

const resource = {
  list: ActivityBoardList,
  show: ActivityBoardShow,
  edit: ActivityBoardEdit,
  icon: LocalActivity
};

export default resource;
