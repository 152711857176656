import { useState } from 'react';

import { Button, useNotify, useRedirect, useRecordContext } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import TipGroupIcon from '@material-ui/icons/MonetizationOn';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  field: { width: '80px' },
  label: {
    position: 'absolute',
    left: '9px',
    fontSize: '0.75rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
  },
  input: { padding: '16px 0 4px 12px' }
});

const CreateTipGroupButton = () => {
  const classes = useStyles();
  const record = useRecordContext();
  const notify = useNotify();
  const redirect = useRedirect();

  const [isProcessing, setIsProcessing] = useState(false);

  const getTipAmountFromScore = score => {
    const pctTotal = Number((score / record.totalScore).toFixed(4));
    const amount = Number(record.prizeFund * pctTotal).toFixed(3);

    return amount;
  };

  // generate tip group data
  const getGroupData = () => {
    const { scores } = record;

    const tips = scores.map(score => {
      const amount = getTipAmountFromScore(score.score);

      return {
        user: score.userId,
        targetId: score.targetId,
        amount,
        status: 'pending'
      };
    });
    console.log('tips: ', tips);

    const tipGroup = {
      targetType: 'activityboards',
      tips
    };
    return tipGroup;
  };

  const token = localStorage.getItem('auth');
  const headers = {
    'Content-Type': 'application/json',
    'x-auth-token': token
  };

  // update activity board after tip group created
  const updateActivityBoard = tipGroup => {
    const { id } = record;

    fetch(`/api/activityboards/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify({
        processed: true,
        tipGroup
      })
    })
      // .then(async res => {
      //   const data = await res.json();
      //   console.log('data: ', data);
      // })
      .catch(e => {
        console.log('Error: update activity board failed', e);
      });
  };

  // create tip group request
  const createTipGroup = () => {
    const groupData = getGroupData();

    setIsProcessing(true);

    fetch(`/api/tipgroups`, {
      method: 'POST',
      headers,
      body: JSON.stringify(groupData)
    })
      .then(async res => {
        const data = await res.json();
        // console.log('data: ', data);

        if (data.tipGroup) {
          updateActivityBoard(data.tipGroup);
        }

        notify(data.message, { type: data.success ? 'success' : 'warning' });
      })
      .catch(e => {
        notify('Error: created tip group failed', { type: 'warning' });
      })
      .finally(() => {
        setIsProcessing(false);
        redirect('/activityboards');
      });
  };

  return record && record.processed ? null : (
    <div className={classes.wrapper}>
      <Button
        label='Create Tip Group'
        onClick={createTipGroup}
        disabled={isProcessing}
      >
        <TipGroupIcon />
      </Button>
    </div>
  );
};

export default CreateTipGroupButton;
