import React from 'react';
import {
  Labeled,
  NumberField,
  ReferenceField,
  TextField,
  useRecordContext
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  tips: {
    display: 'grid',
    gridTemplateRows: 'auto',
    rowGap: '0.5rem',
    paddingTop: '0.5rem',
    paddingBottom: '1rem'
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '10rem 5rem 5rem auto',
    columnGap: '1.5rem',
    width: 'max-content',
    minWidth: '22rem',
    alignItems: 'baseline',
    fontSize: '0.875rem',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: '400',
    lineHeight: '1.43',
    letterSpacing: '0.01071em'
  },
  tip: {
    display: 'grid',
    gridTemplateColumns: '10rem 5rem 5rem auto',
    columnGap: '1.5rem',
    width: 'max-content',
    minWidth: '22rem'
  },
  link: {
    fontSize: '14px',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    color: '#028090'
  },
  msg: {
    textTransform: 'uppercase'
  },
  xrp: {
    fontSize: '12px',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: '400',
    lineHeight: '1.43',
    letterSpacing: '0.01071em'
  }
});

const TipItem = ({ type, tip }) => {
  const classes = useStyles();
  const referenceType = type === 'activityboards' ? 'posts' : type;
  const sourceType = type === 'lessons' ? 'title' : 'description';

  return (
    <div className={classes.tip}>
      <ReferenceField record={tip} source='user' reference='users' link='show'>
        <TextField className={classes.link} source='username' />
      </ReferenceField>

      <div>
        <NumberField record={tip} source='amount' />
        <span className={classes.xrp}> XRP</span>
      </div>

      <TextField record={tip} source='status' className={classes.msg} />

      <ReferenceField
        record={tip}
        source={`targetId`}
        reference={referenceType}
        link='show'
      >
        <TextField className={classes.link} source={sourceType} />
      </ReferenceField>
    </div>
  );
};

const renderTips = (type, tips) =>
  tips.map(tip => <TipItem key={tip._id} type={type} tip={tip} />);

const TipList = props => {
  const { targetType, tips } = useRecordContext(props);
  const classes = useStyles();

  return (
    <div className={classes.tips}>
      <div className={classes.header}>
        <span>Recipient</span>
        <span>Amount</span>
        <span>Status</span>
        <span>Target</span>
      </div>
      {tips.length > 0 ? (
        renderTips(targetType, tips)
      ) : (
        <Labeled className={classes.msg} label='no tips found'></Labeled>
      )}
    </div>
  );
};

export default TipList;
