import React from 'react';
import {
  BooleanField,
  ChipField,
  DateField,
  EditButton,
  FunctionField,
  Labeled,
  ListButton,
  NumberField,
  Show,
  Tab,
  TabbedShowLayout,
  TopToolbar
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import PageTitle from '../PageTitle';
import TipGroupViewTitle from './TipGroupViewTitle';
import TipList from './TipList';
import SubmitTipGroupButton from './SubmitTipGroupButton';

const useStyles = makeStyles({
  toolbar: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  btn: { marginLeft: '0.5rem' },
  link: {
    color: '#028090',
    textDecoration: 'none'
  },
  status: {
    textTransform: 'uppercase',
    fontSize: '14px'
  },
  type: {
    textTransform: 'uppercase',
    '& .MuiChip-root': {
      color: '#fff',
      backgroundColor: '#028090'
    }
  },
  boolean: {
    '& span': {
      textTransform: 'uppercase',
      '& svg[data-testid="false"]': {
        color: 'red'
      },
      '& svg[data-testid="true"]': {
        color: 'green'
      }
    }
  }
});

const TipGroupShowActions = ({ basePath, data }) => {
  const classes = useStyles();

  return (
    <TopToolbar className={classes.toolbar}>
      <TipGroupViewTitle view={'Show Tip Group'} record={data} />
      <div>
        <SubmitTipGroupButton record={data} />
        {!data.finished && <EditButton basePath={basePath} record={data} />}
        <ListButton basePath={basePath} className={classes.btn} />
      </div>
    </TopToolbar>
  );
};

const TipGroupShow = props => {
  const classes = useStyles();

  return (
    <Show
      title={<PageTitle {...props} />}
      actions={<TipGroupShowActions />}
      {...props}
    >
      <TabbedShowLayout>
        <Tab label='Details'>
          <DateField
            className={classes.status}
            source='createdAt'
            showTime={true}
          />
          <DateField
            label='Last Updated At'
            className={classes.status}
            source='updatedAt'
            showTime={true}
          />

          <ChipField
            source='targetType'
            size='small'
            className={classes.type}
          />

          <BooleanField source='submitted' className={classes.boolean} />

          <BooleanField source='finished' className={classes.boolean} />

          <NumberField source='successCount' className={classes.status} />
          <NumberField source='failCount' className={classes.status} />

          <FunctionField
            addLabel={false}
            render={record => {
              return record.maxFeeExceeded ? (
                <Labeled label='Max Fee Exceeded' className={classes.status}>
                  <BooleanField
                    record={record}
                    source='maxFeeExceeded'
                    className={classes.boolean}
                  />
                </Labeled>
              ) : null;
            }}
          />
        </Tab>

        <Tab label='Tips'>
          <TipList />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default TipGroupShow;
