import {
  BooleanField,
  BooleanInput,
  Datagrid,
  EmailField,
  List,
  Pagination,
  SearchInput,
  ShowButton,
  TextField,
  TextInput
} from 'react-admin';

import PageTitle from '../PageTitle';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  listHeader: {
    fontSize: '1.25rem',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: '400',
    color: '#005362',
    margin: 'inherit',
    lineHeight: '1.6',
    letterSpacing: '0.0075em'
  },
  filterInput: {
    width: '22rem'
  }
});

const UserList = props => {
  const classes = useStyles();

  const userFilters = [
    <SearchInput source='q' alwaysOn />,
    <TextInput
      source='payment_account'
      label='Xumm Acct'
      className={classes.filterInput}
    />,
    <BooleanInput source='verified' />
  ];

  return (
    <>
      <h6 className={classes.listHeader}>Users</h6>

      <List
        {...props}
        title={<PageTitle />}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
        filters={userFilters}
      >
        <Datagrid isRowSelectable={() => false} rowClick='show'>
          <TextField source='username' />
          <TextField source='displayName' />
          <EmailField source='email' />
          <TextField source='userRole' />
          <BooleanField source='verified' />
          <ShowButton basePath='/users' label='Show user' />
        </Datagrid>
      </List>
    </>
  );
};

export default UserList;
