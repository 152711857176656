import React from 'react';
import {
  DateField,
  Labeled,
  NumberField,
  ReferenceField,
  TextField,
  useRecordContext
} from 'react-admin';

import { Button, Link } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  tips: {
    display: 'grid',
    gridTemplateRows: 'auto',
    rowGap: '0.5rem',
    paddingTop: '0.5rem',
    paddingBottom: '1rem'
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '10rem 5rem 10rem 8rem auto',
    columnGap: '1rem',
    width: 'max-content',
    minWidth: '30rem',
    alignItems: 'baseline',
    fontSize: '0.875rem',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: '400',
    lineHeight: '1.43',
    letterSpacing: '0.01071em'
  },
  tip: {
    display: 'grid',
    gridTemplateColumns: '10rem 5rem 10rem 8rem auto',
    columnGap: '1rem',
    width: 'max-content',
    minWidth: '30rem',
    alignItems: 'baseline'
  },
  link: {
    textDecoration: 'none'
  },
  userLink: {
    textDecoration: 'none',
    color: '#028090'
  },
  msg: {
    textTransform: 'uppercase'
  },
  xrp: {
    fontSize: '12px',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: '400',
    lineHeight: '1.43',
    letterSpacing: '0.01071em'
  },
  description: {}
});

const UserTips = props => {
  const { tips } = useRecordContext(props);

  const classes = useStyles();

  return (
    <div className={classes.tips}>
      <div className={classes.header}>
        <span>Date</span>
        <span>Amount</span>
        <span>Recipient</span>
        <span>Type</span>
      </div>
      {tips.length > 0 ? (
        tips.map(tip => (
          <div key={tip._id} className={classes.tip}>
            <DateField
              record={tip}
              source='createdAt'
              options={{
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric'
              }}
            />

            <div>
              <NumberField record={tip} source='amount' />
              <span className={classes.xrp}> XRP</span>
            </div>

            <ReferenceField
              link={tip.targetType === 'posts'}
              record={tip}
              source='targetId'
              reference={tip.targetType}
            >
              {tip.targetType === 'posts' ? (
                <TextField className={classes.userLink} source='author' />
              ) : (
                <ReferenceField
                  link={tip.targetType === 'lessons'}
                  source='user'
                  reference='users'
                >
                  <TextField className={classes.userLink} source='username' />
                </ReferenceField>
              )}
            </ReferenceField>

            <TextField record={tip} source='targetType' />

            <Button
              className={classes.link}
              component={Link}
              color='primary'
              underline='none'
              href={
                tip.targetId
                  ? `#/${tip.targetType}/${tip.targetId}/show`
                  : '#/posts'
              }
              startIcon={<VisibilityIcon />}
            >
              Show Target
            </Button>
          </div>
        ))
      ) : (
        <Labeled className={classes.msg} label='no tips found'></Labeled>
      )}
    </div>
  );
};

export default UserTips;
