import React from 'react';
import {
  DateInput,
  Edit,
  ListButton,
  NumberInput,
  RefreshButton,
  SelectInput,
  ShowButton,
  SimpleForm,
  TextInput,
  TopToolbar
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

import { makeStyles } from '@material-ui/core/styles';

import PageTitle from '../PageTitle';
import ChallengeViewTitle from './ChallengeViewTitle';

const useStyles = makeStyles({
  toolbar: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  btn: { marginLeft: '0.5rem' }
});

const ChallengeEditActions = ({ basePath, data }) => {
  const classes = useStyles();
  return (
    <TopToolbar className={classes.toolbar}>
      <ChallengeViewTitle view={'Edit Challenge'} record={data} />
      <div>
        <ShowButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} className={classes.btn} />
        <RefreshButton className={classes.btn} />
      </div>
    </TopToolbar>
  );
};

const ChallengeEdit = props => {
  return (
    <Edit
      title={<PageTitle />}
      actions={<ChallengeEditActions />}
      mutationMode='pessimistic'
      {...props}
    >
      <SimpleForm>
        <TextInput source='companyName' />
        <TextInput source='title' fullWidth />
        <TextInput source='shortDescription' fullWidth />
        <RichTextInput source='description' />
        <SelectInput
          source='category'
          choices={[
            { id: 'social boost', name: 'Social Boost' },
            { id: 'content creation', name: 'Content Creation' },
            { id: 'creative challenge', name: 'Creative Challenge' },
            { id: 'quest', name: 'Quest' },
            { id: 'other', name: 'Other' }
          ]}
        />
        <NumberInput source='rewardsTotal' />
        <NumberInput source='defaultReward' />
        <DateInput source='expirationDate' />
      </SimpleForm>
    </Edit>
  );
};

export default ChallengeEdit;
