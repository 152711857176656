import { useListContext } from 'react-admin';

import { Button } from '@material-ui/core';
import ContentFilter from '@material-ui/icons/FilterList';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  button: {
    justifySelf: 'flex-end'
  }
});

const ChallengeFilterButton = () => {
  const classes = useStyles();
  const { displayedFilters, hideFilter, showFilter } = useListContext();

  const handleClick = () => {
    if (displayedFilters.main) {
      hideFilter('main');
    } else {
      showFilter('main');
    }
  };

  return (
    <Button
      size='small'
      color='primary'
      onClick={() => handleClick()}
      startIcon={<ContentFilter />}
      className={classes.button}
    >
      Filter
    </Button>
  );
};

export default ChallengeFilterButton;
