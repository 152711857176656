import React, { useState } from 'react';
import { useNotify, useRedirect, useUpdate } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/ErrorOutline';

import { removeComment } from '../../util/comments';

const useStyles = makeStyles({
  dialogHeader: {
    color: '#f44336',
    cursor: 'move'
  },
  deleteBtn: {
    width: 'max-content',
    color: '#f44336',
    '&:hover': { backgroundColor: 'rgba(244, 67, 54, 0.12)' }
  }
});

const CommentDelete = ({ record, commentId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const notify = useNotify();
  const redirect = useRedirect();
  const classes = useStyles();

  const toggleModal = () => setIsOpen(!isOpen);

  let diff = {};
  diff.comments = removeComment(commentId, record.comments);

  // useUpdate to send update
  const [update, { loading }] = useUpdate('posts', record.id, diff, record, {
    onSuccess: ({ data }) => {
      redirect(`/posts/${record.id}/show/1`);
      notify('Comment deleted');
    },
    onFailure: error =>
      notify(`Comment deletion error: ${error.message}`, 'warning')
  });
  const handleConfirm = () => {
    update();
    toggleModal();
  };

  return (
    <div>
      <Button
        startIcon={<DeleteIcon />}
        className={classes.deleteBtn}
        disabled={loading}
        onClick={toggleModal}
      >
        Delete Comment
      </Button>
      <Dialog
        open={isOpen}
        onClose={toggleModal}
        aria-labelledby='confirmation-dialog-title'
      >
        <DialogTitle
          className={classes.dialogHeader}
          id='confirmation-dialog-title'
        >
          Delete Comment
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this comment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            startIcon={<CancelIcon />}
            onClick={toggleModal}
            color='default'
          >
            Cancel
          </Button>
          <Button
            startIcon={<CheckCircleIcon />}
            onClick={handleConfirm}
            color='primary'
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CommentDelete;
