import React, { useState } from 'react';

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  FormControlLabel,
  Switch,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Stats from '../Stats';

const useStyles = makeStyles({
  logo: { width: '12rem', height: 'auto', padding: '1rem' },
  header: { color: '#005362' },
  switchLabel: { color: '#005362' },
  colorPrimary: { color: '#005362' },
  btn: {
    float: 'right',
    marginRight: '1rem',
    transform: 'translateY(-3rem)',
    color: '#005362'
  }
});

const Dashboard = () => {
  const [showStats, setShowStats] = useState(false);

  const toggleStats = () => setShowStats(current => !current);

  const classes = useStyles();
  return (
    <>
      <Card>
        <CardMedia
          component='img'
          className={classes.logo}
          alt='logo'
          image='https://res.cloudinary.com/dwlkh8tj3/image/upload/f_auto,q_auto,w_auto,c_scale/assets/img/gfam-logo-teal-trans.png'
          title='logo'
        />
        <CardHeader
          className={classes.header}
          title='Welcome to the gfam admin panel'
        />
        <Button
          className={classes.btn}
          href='https://gfam.live'
          target='_blank'
          rel='noopener noreferrer'
        >
          <Typography variant='button'>Visit gfam.live</Typography>
        </Button>
        <CardContent>
          <FormControlLabel
            control={
              <Switch
                checked={showStats}
                color='primary'
                onChange={toggleStats}
                name='show stats toggle'
                inputProps={{ 'aria-label': 'primary checkbox' }}
                classes={{ colorPrimary: classes.colorPrimary }}
              />
            }
            label='Show App Stats'
            classes={{ label: classes.switchLabel }}
          />
        </CardContent>
        {showStats && <Stats />}
      </Card>
    </>
  );
};

export default Dashboard;
