import AdIcon from '@material-ui/icons/ArtTrack';

import AdCreate from './AdCreate';
import AdEdit from './AdEdit';
import AdList from './AdList';
import AdShow from './AdShow';

const resource = {
  create: AdCreate,
  edit: AdEdit,
  list: AdList,
  show: AdShow,
  icon: AdIcon
};

export default resource;
