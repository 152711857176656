import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  title: {
    color: 'rgba(0, 0, 0, 0.55)',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: '400',
    lineHeight: '1.5',
    letterSpacing: '0.00938em'
  },
  action: { textTransform: 'uppercase', paddingRight: '1rem' }
});

const LeaderboardViewTitle = ({ view, record }) => {
  const classes = useStyles();
  const date = record?.createdAt
    ? new Date(record.createdAt).toUTCString(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
    : '';

  return (
    <span className={classes.title}>
      <span className={classes.action}>
        {view ? `${view} ` : 'Leaderboards'}
      </span>
      <span>{date}</span>
    </span>
  );
};
export default LeaderboardViewTitle;
