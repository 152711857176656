const filterCommentfromArray = (commentId, commentArray) =>
  commentArray.filter(comment => comment._id !== commentId);

const removeComment = (targetId, comments) => {
  let filtered;

  filtered = filterCommentfromArray(targetId, comments);

  if (filtered.length > 0) {
    filtered = filtered.map(comment => {
      if (comment.children && comment.children.length > 0) {
        const filteredReplies = filterCommentfromArray(
          targetId,
          comment.children
        );
        comment.children = filteredReplies;
      }

      return comment;
    });
  }

  return filtered;
};

module.exports = {
  filterCommentfromArray,
  removeComment
};
