import React from 'react';
import {
  DateField,
  DeleteButton,
  EditButton,
  FunctionField,
  ListButton,
  ReferenceField,
  RichTextField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import PageTitle from '../PageTitle';
import ResponseViewTitle from './ResponseViewTitle';

const useStyles = makeStyles({
  toolbar: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  btn: { marginLeft: '0.5rem' },
  category: {
    textTransform: 'capitalize'
  },
  image: {
    maxWidth: '40em',
    maxHeight: '15em'
  },
  link: {
    color: '#028090',
    textDecoration: 'none'
  },
  status: {
    '& span': {
      textTransform: 'uppercase'
    },
    '& label': {
      '& span': {
        textTransform: 'capitalize'
      }
    }
  },
  statusLink: {
    color: '#028090',
    textTransform: 'uppercase'
  }
});

const ResponseShowActions = ({ basePath, data }) => {
  const classes = useStyles();
  return (
    <TopToolbar className={classes.toolbar}>
      <ResponseViewTitle view={'Show Response'} record={data} />
      <div>
        <EditButton basePath={basePath} record={data} />
        <DeleteButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} className={classes.btn} />
      </div>
    </TopToolbar>
  );
};

const ResponseShow = props => {
  const classes = useStyles();

  return (
    <Show
      title={<PageTitle {...props} />}
      actions={<ResponseShowActions />}
      {...props}
    >
      <SimpleShowLayout>
        <DateField
          label='Date'
          source='createdAt'
          options={{
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          }}
        />
        <ReferenceField source='challenge' reference='challenges' link='show'>
          <TextField className={classes.link} source='title' />
        </ReferenceField>

        <ReferenceField
          label='User'
          source='user'
          reference='users'
          link='show'
        >
          <TextField className={classes.link} source='username' />
        </ReferenceField>

        <TextField source='status' className={classes.status} />
        <FunctionField
          label='Image'
          render={record => {
            return (
              <img
                src={`https://res.cloudinary.com/dwlkh8tj3/image/upload/${record.photoPublicId}`}
                alt=''
                className={classes.image}
              />
            );
          }}
        />
        <RichTextField source='content' />

        <ReferenceField
          label='Reward Status'
          source='reward'
          reference='rewards'
          link='show'
        >
          <TextField className={classes.statusLink} source='status' />
        </ReferenceField>
      </SimpleShowLayout>
    </Show>
  );
};

export default ResponseShow;
