import React from 'react';
import { useRecordContext } from 'react-admin';

import ListGroup from './ListGroup';

const PostViews = props => {
  const { leaderboards } = useRecordContext(props);

  const { lists } = leaderboards.find(
    board => board.category.name === 'postViews'
  );

  return <ListGroup lists={lists} />;
};

export default PostViews;
