import React from 'react';
import { Labeled } from 'react-admin';

import List from './List';

import {
  parseStartDateMonth,
  parseStartDateWeek,
  parseStartDateYear
} from './date';
import { useLeaderboardListStyles } from './styles';

const ListGroup = ({ lists }) => {
  const classes = useLeaderboardListStyles();

  const weekStartDate = parseStartDateWeek(lists.week.startDate);
  const monthStartDate = parseStartDateMonth(lists.month.startDate);
  const yearStartDate = parseStartDateYear(lists.year.startDate);

  const NoLeadersMsg = ({ type }) => {
    return (
      <Labeled
        className={classes.msg}
        label={`no ${type} leaders yet`}
      ></Labeled>
    );
  };

  return (
    <div className={classes.list}>
      <div className={classes.header}>Week starting: {weekStartDate}</div>
      {lists.week?.leaders.length > 0 ? (
        <List leaders={lists.week.leaders} />
      ) : (
        <NoLeadersMsg type={lists.week.name} />
      )}

      <div className={classes.headerWithMargin}>Month: {monthStartDate}</div>
      {lists.month?.leaders.length > 0 ? (
        <List leaders={lists.month.leaders} />
      ) : (
        <NoLeadersMsg type={lists.month.name} />
      )}
      <div className={classes.headerWithMargin}>Year: {yearStartDate}</div>
      {lists.year?.leaders.length > 0 ? (
        <List leaders={lists.year.leaders} />
      ) : (
        <NoLeadersMsg type={lists.year.name} />
      )}
    </div>
  );
};

export default ListGroup;
