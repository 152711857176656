import React from 'react';
import { NumberField, ReferenceField, TextField } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import PlaceNumber from './PlaceNumber';

const useStyles = makeStyles({
  leader: {
    display: 'grid',
    gridTemplateColumns: '3rem 0.3fr 1fr',
    gap: '1rem',
    minWidth: '22rem',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  link: {
    color: '#028090',
    textDecoration: 'none'
  }
});

const ListItem = ({ leader, place }) => {
  const classes = useStyles();
  return (
    <div className={classes.leader}>
      <PlaceNumber place={place} />
      <ReferenceField
        record={leader}
        source='user.userId'
        reference='users'
        link='show'
      >
        <TextField className={classes.link} source='username' />
      </ReferenceField>

      <NumberField record={leader} source='score' />
    </div>
  );
};

export default ListItem;
