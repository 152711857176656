import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Confirm,
  fetchStart,
  fetchEnd,
  useNotify,
  useRefresh
} from 'react-admin';

import CloudUpload from '@material-ui/icons/CloudUpload';

const SubmitPaymentsButton = ({ showBtn }) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem('auth');
  const headers = {
    'Content-Type': 'application/json',
    'x-auth-token': token
  };

  const submitPayments = () => {
    setLoading(true);
    dispatch(fetchStart()); // start the global loading indicator

    fetch(`/api/payments`, {
      method: 'POST',
      headers
    })
      .then(async res => {
        const data = await res.json();
        console.log('data: ', data);
        notify(data.message, { type: data.success ? 'success' : 'warning' });
        refresh();
      })
      .catch(e => {
        notify('Error: reward payments not submitted', { type: 'warning' });
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd()); // stop the global loading indicator
      });
  };

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    submitPayments();
    setOpen(false);
  };

  return (
    <>
      {showBtn && (
        <>
          <Button
            label='Submit Payments'
            onClick={handleClick}
            disabled={loading}
          >
            <CloudUpload />
          </Button>

          <Confirm
            isOpen={open}
            title='Submit Payments for Payable Rewards'
            content='Are you sure you want to submit payments for all payable rewards?'
            onConfirm={handleConfirm}
            onClose={handleDialogClose}
          />
        </>
      )}
    </>
  );
};

export default SubmitPaymentsButton;
