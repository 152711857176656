import React from 'react';
import {
  BooleanInput,
  Edit,
  FormTab,
  ListButton,
  RefreshButton,
  ReferenceField,
  ShowButton,
  TabbedForm,
  TextField,
  TextInput,
  TopToolbar
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import PageTitle from '../PageTitle';
import LessonViewTitle from '../Users/UserViewTitle';
import LessonCommentsEdit from './LessonCommentsEdit';

const useStyles = makeStyles({
  toolbar: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  btn: { marginLeft: '0.5rem' },
  link: {
    color: '#028090',
    textDecoration: 'none'
  },
  textInput: {
    width: '100%'
  }
});

const LessonEditActions = ({ basePath, data }) => {
  const classes = useStyles();
  return (
    <TopToolbar className={classes.toolbar}>
      <LessonViewTitle view={'Edit Lesson'} record={data} />
      <div>
        <ShowButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} className={classes.btn} />
        <RefreshButton className={classes.btn} />
      </div>
    </TopToolbar>
  );
};

const LessonEdit = props => {
  const classes = useStyles();

  return (
    <Edit
      title={<PageTitle />}
      actions={<LessonEditActions />}
      mutationMode='pessimistic'
      {...props}
    >
      <TabbedForm redirect='show'>
        <FormTab label='summary'>
          <TextField source='id' />
          <ReferenceField
            label='User'
            source='user'
            reference='users'
            link='show'
          >
            <TextField className={classes.link} source='username' />
          </ReferenceField>
          <TextInput className={classes.textInput} source='title' />
          <TextInput className={classes.textInput} source='description' />
          <TextInput className={classes.textInput} multiline source='content' />
          <BooleanInput label={'Tip Gated Lesson'} source='isTipGated' />
        </FormTab>
        <FormTab label='comments'>
          <LessonCommentsEdit source='comments' />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default LessonEdit;
