import React from 'react';
import {
  DateField,
  FunctionField,
  Labeled,
  useRecordContext
} from 'react-admin';

import { Button, Link } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  posts: {
    display: 'grid',
    gridTemplateRows: 'auto',
    rowGap: '0.5rem',
    paddingTop: '0.5rem',
    paddingBottom: '1rem'
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '10rem 12rem auto ',
    columnGap: '1rem',
    width: 'max-content',
    minWidth: '30rem',
    alignItems: 'baseline',
    fontSize: '0.875rem',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: '400',
    lineHeight: '1.43',
    letterSpacing: '0.01071em'
  },
  post: {
    display: 'grid',
    gridTemplateColumns: '10rem 18rem auto ',
    columnGap: '1rem',
    width: 'max-content',
    minWidth: '30rem',
    alignItems: 'baseline'
  },
  link: {
    textDecoration: 'none'
  },
  msg: {
    textTransform: 'uppercase'
  }
});

const UserPosts = props => {
  const { posts } = useRecordContext(props);
  const classes = useStyles();

  return (
    <div className={classes.posts}>
      <div className={classes.header}>
        <span>Date</span>
        <span>Description</span>
      </div>
      {posts?.length > 0 ? (
        posts.map(post => (
          <div key={post._id} className={classes.post}>
            <DateField
              label='Date'
              record={post}
              source='date'
              options={{
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric'
              }}
            />
            <FunctionField
              label='Description'
              record={post}
              render={record =>
                record.description.length > 42
                  ? `${record.description.slice(0, 42)}...`
                  : record.description
              }
            />
            <Button
              className={classes.link}
              component={Link}
              color='primary'
              underline='none'
              href={`#/posts/${post._id}/show`}
              startIcon={<VisibilityIcon />}
            >
              Show Post
            </Button>
          </div>
        ))
      ) : (
        <div>
          <Labeled className={classes.msg} label='no posts found'></Labeled>
        </div>
      )}
    </div>
  );
};

export default UserPosts;
