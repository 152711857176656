import * as React from 'react';
import { Admin, Resource } from 'react-admin';

import Dashboard from './components/Dashboard';

import dataProvider from './providers/dataProvider';
import authProvider from './providers/authProvider';

import activityboards from './components/ActivityBoards';
import ads from './components/Ads';
import challenges from './components/Challenges';
import chat from './components/Chat';
import leaderboards from './components/Leaderboards';
import lessons from './components/Lessons';
import payments from './components/Payments';
import posts from './components/Posts';
import responses from './components/Responses';
import restrictedusers from './components/RestrictedUsers';
import rewards from './components/Rewards';
import settings from './components/Settings';
import tipgroups from './components/TipGroups';
import users from './components/Users';

import './styles/style.css';

const App = () => (
  <Admin
    dashboard={Dashboard}
    dataProvider={dataProvider}
    authProvider={authProvider}
    title={`gfam admin`}
  >
    <Resource name='users' {...users} />
    <Resource
      name='restrictedusers'
      options={{ label: 'Restricted Users' }}
      {...restrictedusers}
    />
    <Resource name='posts' {...posts} />
    <Resource name='lessons' {...lessons} />
    <Resource name='challenges' {...challenges} />
    <Resource name='responses' {...responses} />
    <Resource name='rewards' {...rewards} />
    <Resource name='payments' {...payments} />
    <Resource
      name='activityboards'
      options={{ label: 'Activity Boards' }}
      {...activityboards}
    />
    <Resource name='chat' {...chat} />
    <Resource name='leaderboards' {...leaderboards} />
    <Resource name='settings' {...settings} />
    <Resource
      name='tipgroups'
      options={{ label: 'Tip Groups' }}
      {...tipgroups}
    />
    <Resource name='ads' {...ads} />
  </Admin>
);

export default App;
