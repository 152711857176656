import React from 'react';
import {
  DeleteButton,
  ListButton,
  Show,
  Tab,
  TabbedShowLayout,
  TopToolbar
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import PageTitle from '../PageTitle';
import LeaderboardViewTitle from './LeaderboardViewTitle';
import PostViews from './PostViews';
import PostCount from './PostCount';
import CommentCount from './CommentCount';
import TipsReceived from './TipsReceived';
import TipsGiven from './TipsGiven';

const useStyles = makeStyles({
  toolbar: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  btn: { marginLeft: '0.5rem' }
});

const LeaderboardShowActions = ({ basePath, data }) => {
  const classes = useStyles();
  return (
    <TopToolbar className={classes.toolbar}>
      <LeaderboardViewTitle view={'Show Leaderboard'} record={data} />
      <div>
        <DeleteButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} className={classes.btn} />
      </div>
    </TopToolbar>
  );
};

const LeaderboardShow = props => {
  return (
    <Show
      title={<PageTitle {...props} />}
      actions={<LeaderboardShowActions />}
      {...props}
    >
      <TabbedShowLayout>
        <Tab label='Post Views'>
          <PostViews source='leaderboards' />
        </Tab>
        <Tab label='Post Count'>
          <PostCount source='leaderboards' />
        </Tab>
        <Tab label='Comment Count'>
          <CommentCount source='leaderboards' />
        </Tab>
        <Tab label='Tips Received'>
          <TipsReceived source='leaderboards' />
        </Tab>
        <Tab label='Tips Given'>
          <TipsGiven source='leaderboards' />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default LeaderboardShow;
