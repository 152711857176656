import React from 'react';
import {
  DateField,
  DeleteButton,
  Edit,
  ListButton,
  NullableBooleanInput,
  ReferenceField,
  RefreshButton,
  SaveButton,
  ShowButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import PageTitle from '../PageTitle';
import RestrictedUserViewTitle from './RestrictedUserViewTitle';

const useStyles = makeStyles({
  topToolbar: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  btn: { marginLeft: '0.5rem' },
  link: {
    color: '#028090',
    textDecoration: 'none'
  },
  permanent: {
    paddingTop: '0.5rem',
    '& label': {
      paddingLeft: '12px'
    }
  }
});

const RestrictedUserActions = ({ basePath, data }) => {
  const classes = useStyles();
  return (
    <TopToolbar className={classes.topToolbar}>
      <RestrictedUserViewTitle view={'Edit Restricted User'} record={data} />
      <div>
        <ShowButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} className={classes.btn} />
        <RefreshButton className={classes.btn} />
      </div>
    </TopToolbar>
  );
};

const RestrictedUserToolbar = props => {
  const classes = useStyles();
  return (
    <Toolbar className={classes.topToolbar} {...props}>
      <SaveButton disabled={props.pristine} />
      <DeleteButton
        label='Remove Restriction'
        basePath={props.basePath}
        variant='text'
      />
    </Toolbar>
  );
};

const RestrictedUserEdit = props => {
  const classes = useStyles();

  return (
    <Edit
      title={<PageTitle {...props} />}
      actions={<RestrictedUserActions />}
      {...props}
    >
      <SimpleForm toolbar={<RestrictedUserToolbar />}>
        <DateField
          label='Date Added'
          source='createdAt'
          options={{
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          }}
        />
        <ReferenceField
          label='Username'
          source='user'
          reference='users'
          link='show'
          sortBy='username'
        >
          <TextField className={classes.link} source='username' />
        </ReferenceField>

        <TextField label='User ID' source='user' />

        <NullableBooleanInput source='permanent' />

        <TextInput source='notes' multiline resettable fullWidth />
      </SimpleForm>
    </Edit>
  );
};

export default RestrictedUserEdit;
