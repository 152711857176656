import React from 'react';
import {
  DateField,
  EditButton,
  FunctionField,
  ListButton,
  NumberField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import PageTitle from '../PageTitle';
import RewardViewTitle from './RewardViewTitle';

const useStyles = makeStyles({
  toolbar: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  btn: { marginLeft: '0.5rem' },
  link: {
    color: '#028090',
    textDecoration: 'none'
  },
  status: {
    '& span': {
      textTransform: 'uppercase'
    },
    '& label': {
      '& span': {
        textTransform: 'capitalize'
      }
    }
  }
});

const RewardShowActions = ({ basePath, data }) => {
  const classes = useStyles();
  return (
    <TopToolbar className={classes.toolbar}>
      <RewardViewTitle view={'Show Reward'} record={data} />
      <div>
        <EditButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} className={classes.btn} />
      </div>
    </TopToolbar>
  );
};

const RewardShow = props => {
  const classes = useStyles();

  return (
    <Show
      title={<PageTitle {...props} />}
      actions={<RewardShowActions />}
      {...props}
    >
      <SimpleShowLayout>
        <DateField
          label='Date'
          source='createdAt'
          options={{
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          }}
        />
        <ReferenceField source='challenge' reference='challenges' link='show'>
          <TextField className={classes.link} source='title' />
        </ReferenceField>

        <ReferenceField
          label='User'
          source='user'
          reference='users'
          link='show'
        >
          <TextField className={classes.link} source='username' />
        </ReferenceField>

        <TextField source='status' className={classes.status} />
        <NumberField source='amount' />
        <ReferenceField
          label='Payment Batch Status'
          source='batch'
          reference='payments'
          link='show'
        >
          <FunctionField
            className={classes.link}
            render={record => (record.finished ? 'Finished' : 'Pending')}
          />
        </ReferenceField>
        <TextField source='txHash' emptyText='No transaction' />
      </SimpleShowLayout>
    </Show>
  );
};

export default RewardShow;
