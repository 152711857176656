const getMonthName = monthIdx => {
  switch (monthIdx) {
    case 0:
      return 'January';
    case 1:
      return 'February';
    case 2:
      return 'March';
    case 3:
      return 'April';
    case 4:
      return 'May';
    case 5:
      return 'June';
    case 6:
      return 'July';
    case 7:
      return 'August';
    case 8:
      return 'September';
    case 9:
      return 'October';
    case 10:
      return 'November';
    case 11:
      return 'December';
    default:
      return 'January';
  }
};

const formatUTCMonth = date => {
  const monthIdx = date.getUTCMonth();

  return getMonthName(monthIdx);
};

export const parseStartDateWeek = timestamp => {
  const date = new Date(timestamp);
  const month = formatUTCMonth(date);
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();

  return `${month} ${day}, ${year}`;
};

export const parseStartDateMonth = timestamp => {
  const date = new Date(timestamp);
  const month = formatUTCMonth(date);
  const year = date.getUTCFullYear();

  return `${month} ${year}`;
};

export const parseStartDateYear = timestamp =>
  new Date(timestamp).getUTCFullYear();
