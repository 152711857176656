import React from 'react';
import { useRecordContext } from 'react-admin';

import ListGroup from './ListGroup';

const PostCount = props => {
  const { leaderboards } = useRecordContext(props);

  const { lists } = leaderboards.find(
    board => board.category.name === 'postCount'
  );

  return <ListGroup lists={lists} />;
};

export default PostCount;
