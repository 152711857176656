import React from 'react';
import {
  DateField,
  Labeled,
  ReferenceField,
  ShowButton,
  TextField,
  useRecordContext
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  list: {
    display: 'grid',
    gridTemplateRows: 'auto',
    rowGap: '0.5rem',
    paddingTop: '0.5rem',
    paddingBottom: '1rem'
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 4fr',
    columnGap: '1rem',
    minWidth: '22rem',
    alignItems: 'baseline',
    fontSize: '0.875rem',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: '400',
    lineHeight: '1.43',
    letterSpacing: '0.01071em'
  },
  response: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 4fr',
    gap: '1rem',
    minWidth: '22rem'
  },
  link: {
    color: '#028090',
    textDecoration: 'none'
  },
  msg: {
    textTransform: 'uppercase'
  },
  status: {
    textTransform: 'uppercase'
  }
});

const ResponseItem = ({ response }) => {
  const classes = useStyles();
  return (
    <div className={classes.response}>
      <DateField
        label='Date'
        record={response}
        source='createdAt'
        options={{
          weekday: 'short',
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }}
      />
      <ReferenceField
        record={response}
        source='user'
        reference='users'
        link='show'
      >
        <TextField className={classes.link} source='username' />
      </ReferenceField>

      <TextField record={response} source='status' className={classes.status} />

      <ShowButton
        basePath='/responses'
        record={{ id: response._id }}
        label='Show Response'
      />
    </div>
  );
};

const renderResponses = responses => {
  const responseItems = responses.map(response => {
    return (
      <span key={response._id}>
        <ResponseItem response={response} />
      </span>
    );
  });
  return responseItems;
};

const ResponseList = props => {
  const { responses } = useRecordContext(props);
  const classes = useStyles();

  return (
    <div className={classes.list}>
      <div className={classes.header}>
        <span>Date</span>
        <span>User</span>
        <span>Status</span>
      </div>

      {responses?.length > 0 ? (
        renderResponses(responses)
      ) : (
        <Labeled className={classes.msg} label='no responses found'></Labeled>
      )}
    </div>
  );
};

export default ResponseList;
