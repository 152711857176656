import UserIcon from '@material-ui/icons/People';

import UserEdit from './UserEdit';
import UserList from './UserList';
import UserShow from './UserShow';

const resource = {
  list: UserList,
  show: UserShow,
  edit: UserEdit,
  icon: UserIcon
};

export default resource;
