import React from 'react';
import {
  NumberField,
  ReferenceField,
  TextField,
  useRecordContext
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  stats: {
    fontSize: '1rem',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: '500',
    color: '#005362',
    paddingTop: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    paddingRight: '2.5rem',
    columnGap: '3rem'
  },
  totalScore: {
    gridArea: 'total'
  },
  fund: {
    gridArea: 'fund'
  },
  scores: {
    display: 'grid',
    gridTemplateRows: 'auto',
    rowGap: '0.5rem',
    paddingTop: '0.5rem',
    paddingBottom: '1rem'
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    columnGap: '1rem',
    minWidth: '30rem',
    alignItems: 'baseline',
    fontSize: '0.875rem',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: '400',
    lineHeight: '1.43',
    letterSpacing: '0.01071em',
    textDecoration: 'underline'
  },
  score: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    columnGap: '1rem',
    minWidth: '30rem'
  },
  link: {
    color: '#028090'
  },
  alignRight: {
    textAlign: 'right',
    paddingRight: '2.5rem'
  },
  xrp: {
    fontSize: '14px',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: '400',
    lineHeight: '1.43',
    letterSpacing: '0.01071em'
  }
});

const ScoreItem = ({ score, totals }) => {
  const classes = useStyles();

  return (
    <div className={classes.score}>
      <ReferenceField
        record={score}
        source='userId'
        reference='users'
        link='show'
      >
        <TextField className={classes.link} source='username' />
      </ReferenceField>

      <NumberField
        record={score}
        source='score'
        options={{ minimumFractionDigits: 1 }}
        className={classes.alignRight}
      />

      <NumberField
        record={totals}
        source='pctTotal'
        options={{ style: 'percent', minimumFractionDigits: 2 }}
        className={classes.alignRight}
      />

      <NumberField
        record={totals}
        source='amount'
        className={classes.alignRight}
      />
    </div>
  );
};

const renderActivityBoardScores = (prizeFund, scores, totalScore) => {
  scores.sort((a, b) => b.score - a.score);

  const scoreItems = scores.map(score => {
    const pctTotal = Number((score.score / totalScore).toFixed(4));
    const amount = Number(prizeFund * pctTotal).toFixed(3);

    const totals = {
      pctTotal,
      amount
    };
    return (
      <span key={score._id}>
        <ScoreItem score={score} totals={totals} />
      </span>
    );
  });
  return scoreItems;
};

const ActivityBoardScores = props => {
  const classes = useStyles();
  const { prizeFund, scores, totalScore } = useRecordContext(props);

  return (
    <div>
      <div className={classes.stats}>
        <span className={classes.totalScore}>Total Score: {totalScore}</span>
        <span className={classes.fund}>
          Prize Fund: {prizeFund} <span className={classes.xrp}> XRP</span>
        </span>
      </div>
      <div className={classes.scores}>
        <div className={classes.header}>
          <span>User</span>
          <span className={classes.alignRight}>Activity Score</span>
          <span className={classes.alignRight}>% Total Score</span>
          <span className={classes.alignRight}>Amount XRP</span>
        </div>
        {renderActivityBoardScores(prizeFund, scores, totalScore)}
      </div>
    </div>
  );
};

export default ActivityBoardScores;
