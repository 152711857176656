import { useState } from 'react';
import {
  Button,
  Confirm,
  useListContext,
  useUpdateMany,
  useNotify,
  useRefresh,
  useUnselectAll
} from 'react-admin';

const { CheckCircle } = require('@material-ui/icons');

const AcceptResponseButton = () => {
  const { selectedIds } = useListContext();
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll('responses');

  const [updateMany, { isLoading }] = useUpdateMany(
    'responses',
    selectedIds, // the ids of the records to update
    { status: 'accepted' }, // update data
    {
      onSuccess: () => {
        refresh();
        notify('Responses updated');
        unselectAll('responses');
      },
      onFailure: error =>
        notify('Error: responses not updated', { type: 'warning' })
    }
  );

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    updateMany();
    setOpen(false);
  };

  return (
    <>
      <Button label='Mark Accepted' onClick={handleClick}>
        <CheckCircle />
      </Button>
      <Confirm
        isOpen={open}
        loading={isLoading}
        title='Mark Responses Accepted'
        content='Are you sure you want to accept these responses?'
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

export default AcceptResponseButton;
